<template>
   <div>
      <div class="grid chat grid-cols-12 gap-6">
        <div class="col-span-12">
          <!-- add rule -->
          <div class="w-full" :class="!aRule ? 'hidden' : ''">
            <div class="text-base pb-3 pt-2 font-medium"><FilterIcon class="w-4 h-4" /> Nueva regla</div>
            <form ref="addRule" class="validate-form" @submit.prevent="addRule('addRule',$event)">
              <div class="p-5 pb-2 bg-gray-200">
                <select name="rule_type" class="form-select cursor-pointer w-1/5 mb-3"><option value="onpredict">Predicción</option></select>
                <select name="rule_model_final_tag" class="form-select cursor-pointer w-1/4 mb-3 ml-5" required>
                  <option v-for="(t,indx) in tags" :key="t" :value="t ? indx : ''" :selected="indx==eRule.model_final_tag">
                    <span v-if="t">{{indx}} - {{ t }}</span><span v-else>Seleccione el Tag final</span>
                  </option>
                </select>
                <select name="rule_model_active" class="form-select cursor-pointer w-1/4 mb-3 ml-5">
                  <option value="0">Inactiva</option>
                  <option value="1" :selected="eRule.active">Activa</option>
                </select>
                <button class="btn btn-primary px-5 ml-5" type="submit">Guardar</button>
                <button class="btn btn-outline-primary px-5 ml-2" v-on:click="aRule=false">Cancelar</button>
              </div>
            </form>
          </div>
          <!-- edit rule -->
          <div class="w-full" :class="!eRule ? 'hidden' : ''">
            <div class="text-base pb-3 pt-2 font-medium">
              <FilterIcon class="w-4 h-4" /> Editar regla <span class="text-xs text-gray-600">/ {{eRule.id}}</span>
               <span class="mb-4 text-xs ml-5 text-gray-600" v-if="eRule.createdAt && !eRule.updatedAt"><ClockIcon class="w-4 h-4" /> Creado: {{ this.helper.getTimestampDate(eRule.createdAt.toDate(),'date')+" "+this.helper.getTimestampDate(eRule.createdAt.toDate(),'time') }}</span>
               <span class="mb-4 text-xs ml-5 text-gray-600" v-if="eRule.updatedAt"><ClockIcon class="w-4 h-4" /> Actualizado: {{ this.helper.getTimestampDate(eRule.updatedAt.toDate(),'date')+" "+this.helper.getTimestampDate(eRule.updatedAt.toDate(),'time') }}</span>
            </div>
            <form ref="updRule" class="validate-form" @submit.prevent="updateRule('updRule',$event)">
            <input type="hidden" name="rule_id" :value="eRule.id" />
            <div class="p-5 bg-gray-200 mb-5">
              <select name="rule_type" class="form-select cursor-pointer w-1/5 mb-3">
                  <option value="onpredict">Predicción</option>
              </select>
              <select name="rule_model_final_tag" class="form-select cursor-pointer w-1/4 mb-3 ml-5" required>
                <option v-for="(t,indx) in tags" :key="t" :value="t ? indx : ''" :selected="indx==eRule.model_final_tag"><span v-if="t">{{indx}} - {{ t }}</span><span v-else>Seleccione el Tag final</span></option>
              </select>
              <select name="rule_model_active" class="form-select cursor-pointer w-1/4 mb-3 ml-5">
                <option value="0">Inactiva</option>
                <option value="1" :selected="eRule.active">Activa</option>
              </select>
              <div :class="querybuilder && querybuilder.value ? '' : 'hidden'">
                <smart-query-builder id="queryBuilder" ref="querybuilder"></smart-query-builder>
              </div>
            </div>
            <div class="mt-5">
              <button class="btn btn-primary px-5" type="submit">Guardar</button>
              <button class="btn btn-outline-secondary px-5 ml-2" v-on:click="eRule=false">Cancelar</button>
            </div>
            </form>
          </div>
          <!-- rule list -->
          <div :class="eRule || aRule ? 'hidden' : ''">
            <div class="intro-y block sm:flex items-center h-10">
              <span class="text-base font-medium truncate mr-5">Reglas de uso</span>
              <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
                <button class="btn btn-secondary box flex items-center text-gray-700 dark:text-gray-300" v-on:click="aRule=true">
                  <PlusIcon class="hidden sm:block w-4 h-4 mr-2" /> Nueva regla
                </button>
              </div>
            </div>
            <table class="table table--sm mb-5 mt-3" v-if="Object.keys(MonRules).length">
              <tbody>
                  <tr v-for="(mr,indx) in MonRules" :key="mr">
                    <td class="border w-2/6 font-medium cursor-pointer bg-gray-200 text-gray-600" @click="EditRule(indx)"><FilterIcon class="w-4 h-4 mr-2" /> {{indx}}</td>
                    <td class="border w-1/6"><span v-if="mr.type=='onpredict'">Predicción</span><span v-else>{{mr.type}}</span></td>
                    <td class="border w-1/6">MFT ({{mr.model_final_tag}})</td>
                    <td class="border w-1/6"><span class="text-red-700" v-if="!mr.active">Inactiva</span><span class="text-green-700" v-else>Activa</span></td>
                    <td class="border w-1/6 text-center">
                      <TrashIcon class="w-4 h-4 mr-2 cursor-pointer" @click="deleteRule(indx)" />
                      <!-- Delete Array item -->  
                      <div :id="'delete-rule-'+indx" class="modal" tabindex="-1" aria-hidden="true" >
                          <div class="modal-dialog">
                              <div class="modal-content">
                                  <div class="modal-body p-0">
                                      <div class="p-5 text-center">
                                          <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                          <div class="text-2xl mt-5">Eliminar regla de uso</div>
                                          <div class="text-gray-600 mt-2">{{ indx }}</div>
                                          <div class="text-gray-600 mt-2">{{ props.monitor }}</div>
                                          <div class="text-gray-600 mt-2">¿Está seguro que desea eliminar la regla? <br />Esta acción és irreversible.</div>
                                      </div>
                                      <div class="px-5 pb-8 text-center">
                                          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">Cancelar</button>
                                          <button type="button" class="btn btn-danger w-24" @click="delRule(indx)">Eliminar</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                    </td>
                  </tr>
                </tbody>
            </table>
            <div v-else>No se han registrado reglas para este monitor.</div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted , onBeforeMount, watch  } from "vue";
import { monitor as $monitor } from "@/model/monitor";
import { helper as $h } from "@/utils/helper";
import { tag as $tag } from "@/model/tag";
import cash from "cash-dom";
import "smart-webcomponents/source/styles/smart.default.css";
import "smart-webcomponents/source/modules/smart.querybuilder.js";

export default defineComponent({
  props: {
    monitor: {
      type: String,
      required: true
    }
  },
  created () {
    this.helper     = $h
  },  
  setup(props) {
    
    const MonRules      = ref({});
    const rules         = ref([])
    const eRule         = ref(false);
    const aRule         = ref(false);
    const querybuilder  = ref(null)
    const tags          = ref([])

    const EditRule = async (r) => { 

      eRule.value = MonRules.value[r];
      if(querybuilder.value)querybuilder.value.value = [];
      tags.value = await $tag.init()

      let tagsOpt = [] 
      Object.keys(tags.value).map(function(tagID){ tagsOpt.push(tagID) });

      let modelConfidenceOpt = [] 
      for (let i= 0; i< 1.05; i= i+0.05) { modelConfidenceOpt.push(i.toFixed(2).toString()); }
      
      let ruleConditions = []
      if(eRule.value.conditions)ruleConditions =JSON.parse(eRule.value.conditions)

      window.Smart(
        "#queryBuilder",
        class {
          get properties() {
            return {
              allowDrag: true,
              locale: 'es',
              fields: [
                {
                  label: "Tag prediction",
                  dataField: "prediction_tag",
                  dataType: "number",
                  filterOperations: ["=", "<", "<=", ">", ">="],
                  lookup: {
                    dataSource: tagsOpt
                  }
                },
                {
                  label: "Límite de confianza",
                  dataField: "model_confidence",
                  filterOperations: ["=", "<", "<=", ">", ">="],
                  dataType: "number",
                  lookup: {
                    dataSource: modelConfidenceOpt
                  }
                },
              ],
              showIcons: true,
              operatorPlaceholder: "Operador",
              propertyPlaceholder: "Atributo",
              messages: {
                          "es": {
                                  "propertyUnknownType": "'{{name}}' property is with undefined 'type' member!",
                                  "propertyInvalidValue": "Invalid '{{name}}' property value! Actual value: {{actualValue}}, Expected value: {{value}}!",
                                  "propertyInvalidValueType": "Invalid '{{name}}' property value type! Actual type: {{actualType}}, Expected type: {{type}}!",
                                  "elementNotInDOM": "Element does not exist in DOM! Please, add the element to the DOM, before invoking a method.",
                                  "moduleUndefined": "Module is undefined.",
                                  "missingReference": "{{elementType}}: Missing reference to {{files}}.",
                                  "htmlTemplateNotSuported": "{{elementType}}: Browser doesn't support HTMLTemplate elements.",
                                  "invalidTemplate": "{{elementType}}: '{{property}}' property accepts a string that must match the id of an HTMLTemplate element from the DOM.",
                                  "add": "Añadir",
                                  "addCondition": "Add Condition",
                                  "addGroup": "Add Group",
                                  "and": "And",
                                  "notand": "Not And",
                                  "or": "Or",
                                  "notor": "Not Or",
                                  "=": "Igual a",
                                  "<>": "No es igual",
                                  ">": "Mayor a",
                                  ">=": "Mayor o igual a",
                                  "<": "Menor a",
                                  "<=": "Menor o igual a",
                                  "startswith": "Starts with",
                                  "endswith": "Ends with",
                                  "contains": "Contains",
                                  "notcontains": "Does not contain",
                                  "isblank": "Is blank",
                                  "isnotblank": "Is not blank",
                                  "wrongParentGroupIndex": "{{elementType}}: Wrong parent group index in '{{method}}' method.",
                                  "missingFields": "{{elementType}}: Fields are required for proper condition's adding. Set \"fields\" source and then conditions will be added as expected.",
                                  "wrongElementNode": "{{elementType}}: Incorect node / node Id in '{{method}}' method.",
                                  "invalidDataStructure": "{{elementType}}: Used invalid data structure in updateCondition/updateGroup method.",
                                  "dateTabLabel": "DATE",
                                  "timeTabLabel": "TIME",
                                  "queryLabel": "Condiciones"
                                }
                        },
              value: ruleConditions
            };
          }
        }
      );

      if(querybuilder.value)querybuilder.value.value = ruleConditions;
    };

    const deleteRule =  (r) => {  cash('#delete-rule-'+r).modal("show");  }; 

    onBeforeMount( async () => {
      MonRules.value = await $monitor.getRules(props.monitor)
    });

    onMounted( async () => {
      tags.value = await $tag.init()
      if(querybuilder.value)querybuilder.value.addEventListener('change', function () { rules.value = querybuilder.value.value  });
    });

    watch(() => props.monitor, async () => { MonRules.value = await $monitor.getRules(props.monitor) });

    return {
      props,
      rules,
      querybuilder,
      MonRules,
      EditRule,
      deleteRule,
      eRule,
      aRule,
      tags
    };
  },
  methods:{
    updateRule: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let updRule = { monitor: this.props.monitor, data: {}}
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="rule_id")updRule['id'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_type")updRule.data['type'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_model_final_tag")updRule.data['model_final_tag'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_model_active"){ updRule.data['active'] = Boolean(false); if(this.$refs[formID][i].value=="1")updRule.data['active'] = Boolean(true);  }
        }  
        updRule.data["conditions"] = JSON.stringify(this.querybuilder.value);
        await $monitor.updateRule(updRule)
        $h.NotificationTxt({text:'Regla actualizada correctamente'})
        this.eRule = false
        this.MonRules= await $monitor.getRules(this.props.monitor)
      }
    },
    addRule: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let addRule = { monitor: this.props.monitor, data: {} }
        for (let i = 0; i < this.$refs[formID].length; i++) {  
         if(this.$refs[formID][i].name=="rule_id")addRule['id'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_type")addRule.data['type'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_model_final_tag")addRule.data['model_final_tag'] = this.$refs[formID][i].value;
         if(this.$refs[formID][i].name=="rule_model_active"){  addRule.data['active'] = Boolean(false); if(this.$refs[formID][i].value=="1")addRule.data['active'] = Boolean(true);  }
        }  
        addRule.data["conditions"] = "[]";
        let newRuleID = await $monitor.addRule(addRule)
        $h.NotificationTxt({text:'Nueva regla creada correctamente'})
        this.aRule = false
        this.MonRules = await $monitor.getRules(this.props.monitor)
        if(newRuleID)await this.EditRule(newRuleID)
      }
    },
    delRule: async function(ruleID){
      cash('#delete-rule-'+ruleID).modal("hide"); cash('#delete-rule-'+ruleID).removeClass('show');
      await $monitor.delRule(this.props.monitor,ruleID)
      $h.NotificationTxt({text:'Regla eliminada correctamente'})
      this.MonRules = await $monitor.getRules(this.props.monitor)
    },
  }
});
</script>
<style>
    :root {
      --smart-query-builder-min-width: 100%;
      --smart-font-family: Roboto !important;
    }
</style>