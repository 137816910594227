<template>
  <div
    class="top-bar-boxed border-b border-theme-2 -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12">
    <div class="h-full flex items-center">
      <a href="" class="-intro-x hidden md:flex">
        <img alt="Rosepetal" class="w-56" src="@/assets/images/logo/logorosepetal.png" />
      </a>
      <div class="-intro-x breadcrumb mr-auto text-lg"><div v-if="Clock"><ClockIcon class="mr-2" /> {{ date }}</div></div>
      <div class="intro-x dropdown w-8 h-8 mr-5">
        <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-theme-24 text-center pt-0.5" role="button" aria-expanded="false" @click="ShowMenu()">
          <span class="text-white text-xl" v-if="User.displayName">{{ User.displayName.charAt(0) }}</span>
        </div>
        <div class="dropdown-menu w-56">
          <div class="dropdown-menu__content box bg-theme-11 dark:bg-dark-6 text-white" v-if="menuvisible">
            <div class="p-4 border-b border-theme-12 dark:border-dark-3">
              <div class="font-medium" v-if="User.displayName">{{ User.displayName }}</div>
              <div class="text-xs text-theme-13 mt-0.5 dark:text-gray-600" v-if="User.email">{{ User.email }}</div>
              <div class="text-xs text-theme-13 mt-0.5 dark:text-gray-600" v-if="User.role">{{ User.role.name }}</div>
            </div>
            <div class="p-2">
              <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer" @click="linkTo('side-menu-profile')">
                <UserIcon  class="w-4 h-4 mr-2" /> Mi cuenta
              </a>
              <a v-if="User.role && root" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer" @click="linkTo('side-menu-settings')">
                <SlidersIcon  class="w-4 h-4 mr-2" /> Configuración
              </a>
              <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer" @click="linkTo('doc')">
                <InfoIcon  class="w-4 h-4 mr-2" /> Documentación
              </a>
            </div>
            <div class="p-2 border-t border-theme-12 dark:border-dark-3">
              <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer TopBarMenu" @click="Logout" >
                <PowerIcon class="w-4 h-4 mr-2" /> Cerrar sesión
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { _firebase as $_firebase } from "@/model/firebase";
import { event as $event } from "@/model/events";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { view as $v } from "@/model/view";


export default defineComponent({
  setup() {
    const store       = useStore();
    const User        = computed(() => store.state.main.User);
    const root        = ref(false);
    const router      = useRouter();
    const date        = ref();
    const menuvisible = ref(true)
    const Clock       = ref(false)

    onMounted(async () =>  {
     Clock.value = await $v.getSettings('layout','clock');
     if(Clock.value){
      setInterval(() => {
        const current = new Date();
        date.value   = current.getDate()+'/'+(current.getMonth()+1)+'/'+current.getFullYear();
        date.value  += ' '+ (current.getHours()<10?'0':'')+current.getHours() + ":" + (current.getMinutes()<10?'0':'')+current.getMinutes() + ":" + (current.getSeconds()<10?'0':'')+current.getSeconds();
      }, 1000)
     }
     if(store.state.main.User.role && (store.state.main.User.role.role_id==2 || store.state.main.User.role.role_id==3))root.value = true; 
    });

    const Logout = async () => {
        menuvisible.value=false;
        await $event.saveEvent('user.logout', { uid: useStore().state.main.User.uid }, false)
        $_firebase.auth().signOut().then(() => console.log('Sign Out')).catch(err => alert(err.message));
    }

    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && (store.state.main.User.role.role_id==2 || store.state.main.User.role.role_id==3))root.value = true;  });

    const linkTo = (page) => {  menuvisible.value = false; router.push({ name: page  });  };

    return {
      date,
      Logout,
      linkTo,
      Clock,
      User,
      menuvisible,
      root,
    };
    
  }, 
  methods: {
    ShowMenu(){ this.menuvisible=true }
  }
});
</script>
