<template>
    <div class="grid grid-cols-12 gap-6">
      <!-- Search -->
      <div class="col-span-12 lg:col-span-3 xl:col-span-3 xxl:col-span-2" v-if="LayoutOptions.search">
        <div class="intro-y box p-5">
          <div class="relative flex items-center mb-3"><div class="font-medium text-xl">Búsqueda</div></div>
          <div class="text-xs mb-3">Fecha o tramo de fechas</div>
          <div class="intro-y block sm:flex items-center mb-4 border border-gray-300">
            <CalendarIcon class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-1.5 left-0 cursor-pointer" />
            <Litepicker
              ref="Litepicker"
              v-model="DateRange"
              :options="{
                    autoApply: true,
                    singleMode: false,
                    numberOfColumns: 2,
                    numberOfMonths: 2,
                    showWeekNumbers: true,
                    format: 'DD/MM/YYYY',
                    lang: 'es-ES',
                    buttonText: { apply: 'Buscar', cancel: 'Cancelar'},
                    maxDays: 60,
                    maxDate: new Date(),
                    dropdowns: {
                      minYear: new Date().getFullYear(),
                      maxYear: null,
                      months: true,
                      years: false
                    }
                  }"
                 :disabled="Loading"
              class="form-control w-full box pl-6 cursor-pointer" />
          </div>
          <div class="text-xs mb-3">Filtrar resultados</div>
          <div v-html="this.tableModel.renderFilter(filter,filterOpt)"></div>
          <div class="mt-5">
            <span v-html="this.tableModel.renderSearchBtn()" ref="SearchBtn"></span>
            <span v-html="this.tableModel.renderResetBtn()"  ref="resetBtn"></span>
          </div>
        </div>
      </div>  
      <div class="col-span-12 lg:col-span-9 xl:col-span-9 xxl:col-span-10">
        <!-- stats -->
        <div class="col-span-12 grid grid-cols-12 gap-6 mb-5" :class="LayoutOptions.stats && !Loading ? '' : 'hidden'">
          <!-- Results -->
          <div class="col-span-12 sm:col-span-6 intro-y" :class="!Stats.count ? 'xxl:col-span-4' : 'xxl:col-span-3'">
            <div class="box p-5 zoom-in pt-0 StatsBoxSession">
              <div class="flex pt-5">
                  <div class="text-lg font-medium truncate mr-3"><span v-if="Stats.total!=Stats.count">Resultados</span><span v-else>Todas</span></div>
                  <div class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate">
                    <CalendarIcon class="w-4 h-4 z-10 mr-1" />{{ this.helper.dateRangeLabel(DateRange) }}
                  </div>
              </div>
              <div class="flex items-center">
                <div class="w-2/4 flex-none" :class="Stats.filterGraph && Stats.count && tableData.length!=Stats.count ? 'mt-4' : 'mt-8'">
                  <div class="inline-block align-top"><ActivityIcon class="w-12 h-12 z-10 text-gray-300" /></div>
                  <div class="inline-block align-top pl-2">
                    <div class="text-3xl font-medium truncate text-theme-17 dark:text-theme-3">
                      {{ this.helper.formatScore(Stats.count) }}
                      <span v-if="tableData.length!=Stats.count" class="text-xs">/ {{ this.helper.formatScore(tableData.length) }}</span></div>
                    <div class="text-gray-600">{{ itemName }}</div>
                  </div>
                </div>
                <div class="flex-none ml-auto relative mt-4" v-if="Stats.filterGraph && Stats.count && tableData.length!=Stats.count">
                  <ReportDonutChart1 :width="85" :height="85" :data="Stats.filterGraph" />
                  <div class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0">{{ Stats.percentage + '%' }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Count Analyze -->
          <div class="col-span-12 sm:col-span-6 xxl:col-span-2 intro-y" v-if="Stats.analyze">
            <div class="box p-5 StatsBoxSession text-center">
                <div class="text-lg font-medium truncate items-center">Imágenes</div>
                <div class="text-center mt-1">
                  <div class="text-3xl text-theme-17 dark:text-theme-3 leading-8 font-bold">{{ this.helper.formatScore(Stats.analyze) }}</div>
                  <div class="text-base text-gray-600">Analizadas</div>
                </div>
                <button class="btn btn-outline-secondary border-dashed w-full py-1 px-2 mt-3" @click="linkTo('side-menu-log', this.helper.dateRangeLabel(DateRange))">Ver registro</button>
            </div>
          </div> 
          <!-- Activity -->
          <div class="col-span-12 sm:col-span-12 xl:col-span-6 xxl:col-span-4 intro-y" v-if="Stats.totalGraphLimit && Stats.totalGraph.labels.length">
            <div class="box p-5 pb-2 zoom-in StatsBoxSession">
              <div class="flex">
                <div class="text-lg font-medium truncate mr-3">Actividad</div>
                <div class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate">{{ Stats.totalGraphLimit }} {{ itemName }}</div>
              </div>
              <div class="mt-3" v-if="Stats.totalGraph"><SimpleLineChart1 :height="80" class="-ml-1" :data="Stats.totalGraph" /></div>
            </div>
          </div>
          <!-- Duration -->
          <div class="col-span-12 sm:col-span-6 xxl:col-span-3 intro-y" v-if="Stats.duration && Stats.duration.total">
            <div class="box p-5 StatsBoxSession">
                <div class="flex">
                  <div class="text-lg font-medium truncate mr-3">Duración media</div>
                  <div class="py-1 px-2 rounded-full text-xs bg-gray-200 dark:bg-dark-5 text-gray-600 dark:text-gray-300 cursor-pointer ml-auto truncate">{{ Stats.totalGraphLimit }} {{ itemName }}</div>
                </div>
                <div class="inline-block align-top pt-4 pr-2"><ClockIcon class="w-14 h-14 z-10 mr-1 text-gray-200" /></div>
                <div class="inline-block pr-6 text-center pt-1">
                  <div class="text-3xl text-theme-17 dark:text-theme-3 leading-8 mt-4">{{Stats.duration.average.hours}}</div>
                  <div class="text-xs text-gray-600 mt-1">Horas</div>
                </div>
                <div class="inline-block pr-5 text-center pt-1">
                  <div class="text-3xl text-theme-17 dark:text-theme-3 leading-8 mt-4">{{Stats.duration.average.minutes}}'</div>
                  <div class="text-xs text-gray-600 mt-1">Minutos</div>
                </div>
                <div class="inline-block text-center pt-1">
                  <div class="text-3xl text-theme-17 dark:text-theme-3 leading-8 mt-4">{{Stats.duration.average.seconds}}″</div>
                  <div class="text-xs text-gray-600 mt-1">Segundos</div>
                </div>
                <div class="text-xs mt-3 text-gray-500" v-if="Stats.duration.duration">Duración total: <span class="text-gray-600">{{Stats.duration.duration}}</span></div>
            </div>
          </div> 
        </div>
        <!-- Table -->
        <div class="intro-y box" :class="Loading ? 'hidden' : ''">
          <div class="relative flex items-center p-5 pb-0 pt-3">
            <div class="font-medium text-xl">
              <span v-if="Stats.total!=Stats.count">Resultados</span><span v-else>Historial de {{ itemName }}</span>
              <!-- Filter active -->
              <span v-if="filter.value">
                <span class="text-gray-500 pl-10 text-base"><FilterIcon class="w-4 h-4 z-10" /> Filtro aplicado:</span>
                <span v-for="f in filterOpt" v-bind:key="f.id"><span class="font-bold text-theme-17 text-base pl-3" v-if="f.id==filter.field">{{ f.name }}</span></span>
                <span v-for="o in filterOperator" v-bind:key="o.id"><span class="text-gray-500 text-base pl-2" v-if="o.id==filter.type"><span v-if="o.name">{{ o.name.toLowerCase() }}</span><span v-else>{{ o.id }}</span></span></span>
                <span v-if="filter.value"><span class="font-bold text-theme-17 truncate text-base pl-2">"{{ filter.value }}"</span></span>
              </span>
            </div>  
            <div class="ml-auto flex">
                <div v-html="this.tableModel.renderPrintBtn()" ref="PrintBtn"></div>
                <div class="dropdown w-1/2 sm:w-auto"><div v-html="this.tableModel.renderExportBtn()" ref="ExportBtn"></div></div>   
            </div>
          </div>
          <div class="p-5 pt-0 pb-5 block">
            <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
            </div>
          </div>
        </div>
        <!-- Modal item Details -->
        <div id="modal-item-details" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20 mb-20" v-if="LoadDetails"> 
                            <LoadingIcon icon="three-dots" class="w-20 h-20 pt-4" />
                            <span class="text-xs pb-8">Cargando datos de sesión, espere por favor...</span>
                        </div>
                        <div :class="LoadDetails ? 'hidden' : ''">
                          <sessionlog :session_id="itemDetails" />
                          <div class="px-5 pb-8 text-center">
                            <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">Cerrar</button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="Loading">
          <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20 mb-20"> 
              <LoadingIcon icon="three-dots" class="w-20 h-20" />
              <span class="text-base">Analizando sesiones, espere por favor...</span>
          </div>
        </div>
      </div> 
    </div> 
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { session as $session } from "@/model/session";
import { table as $table } from "@/model/table";
import { helper as $h } from "@/utils/helper";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import cash from "cash-dom";

import sessionlog from "@/components/session-log/Main.vue";

import ReportDonutChart1 from "@/components/report-donut-chart-1/Main.vue";
import SimpleLineChart1 from "@/components/simple-line-chart-1/Main.vue";

export default defineComponent({
  components: {
    sessionlog,
    ReportDonutChart1,
    SimpleLineChart1
  },
  props: {
    options: {
      type: Object,
    }
  },
  created () {
    this.tableModel   = $table,
    this.helper       = $h
  },  
  setup(props) {

    const router           = useRouter();
    const LayoutOptions    = ref({ search: true, stats: true })
    const Loading          = ref(false)
    const Stats            = ref({})
    const tableData        = ref([])
    const tableRef         = ref()
    const tabulator        = ref()
    const filterOperator   = ref([])
    const PrintBtn         = ref(null)
    const ExportBtn        = ref(null)
    const SearchBtn        = ref(null)
    const resetBtn         = ref(null)
    const itemName         = ref('Sesiones')
    const filterOpt        = ref([
                                  { id: 'id',         name: 'ID'},
                                  { id: 'monitor',    name: 'Monitor'}, 
                                  { id: 'data.total', name: 'Imágenes analizadas'},
                                  { id: 'uid',        name: 'Usuario'}
                                ])
    const filter           = reactive({ field: filterOpt.value[0].id, type: "like",  value: "" });
    const itemDetails      = ref()
    const LoadDetails      = ref(false)
    const DateRange        = ref(dayjs().subtract(1, "week").format('DD/MM/YYYY')+" - "+dayjs().format('DD/MM/YYYY'));

    const tableComuns   = () => { 
      return [ 
        { formatter: "responsiveCollapse", width: 40, minWidth: 30, hozAlign: "left", resizable: false, headerSort: false },
        // HTML 
        { title: "ID Sesión", minWidth: 120, responsive: 1, field: "id", vertAlign: "middle", print: false, download: false, headerSort: false, formatter(cell) { return `<div class="${cell.getData().stop ? "text-gray-600" : "text-green-900"} font-medium text-xs whitespace-nowrap">${cell.getData().id}</div>`;} },
        { title: "Monitor", maxWidth: 130, responsive: 1, field: "monitor", vertAlign: "middle", hozAlign: "center", print: false, download: false, 
          formatter(cell) { return `<div class="py-1 px-2 rounded-full text-xs bg-gray-300 text-gray-600 cursor-pointer font-medium">${cell.getData().monitor}</div>`;} },
        { title: "Inicio", minWidth: 130, responsive: 1, field: "start_seconds", vertAlign: "middle", hozAlign: "center", headerSort: true, sorter:"number", print: false, download: false, 
          formatter(cell) { 
            if(cell.getData().start_date)return `<div class="${cell.getData().stop ? "text-gray-600" : "text-green-900"} text-xs whitespace-nowrap">${cell.getData().start_date}</div>`
          } 
        },
        { title: "Final", minWidth: 130, responsive: 1, field: "stop_seconds", vertAlign: "middle", hozAlign: "center", headerSort: true, sorter:"number", print: false, download: false, 
          formatter(cell) { 
            if(cell.getData().stop_date)return `<div class="${cell.getData().stop ? "text-gray-600" : "text-green-900"} text-xs whitespace-nowrap">${cell.getData().stop_date}</div>`
          } 
        },
        { title: "Duración", minWidth: 150, responsive: 1, field: "duration.unix", vertAlign: "middle", hozAlign: "center", sorter:"number", headerSort: true, print: false, download: false,
          formatter(cell) { if(cell.getData().stop){ if(cell.getData().stop)return `<div class="text-gray-600 text-xs whitespace-nowrap">${cell.getData().duration.hours ? Math.floor(cell.getData().duration.hours)+"h" : ""} ${Math.floor(cell.getData().duration.minutes)}m ${Math.floor(cell.getData().duration.seconds)}s</div>`;}
          } 
        },
        { title: "Analizadas", minWidth: 80, responsive: 1, field: "data.total", vertAlign: "middle", hozAlign: "center", print: false, download: false,formatter(cell) { if(cell.getData().stop)return `<div><div class="text-gray-600 text-xs whitespace-nowrap">${cell.getData().data.total ? cell.getData().data.total : 0}</div></div>`;} },
        //{ title: "Usuario", minWidth: 180, responsive: 1, field: "uid", vertAlign: "middle", print: false, download: false, formatter(cell) { return `<div class="text-gray-600 text-xs">${cell.getData().uid ? cell.getData().uid : '<span class="text-red-600">-</span>'}</div>`;} },
        { title: "Estado", minWidth: 140,field: "status",vertAlign: "middle",print: false, download: false, headerSort: false, hozAlign: "center", 
         formatter(cell) { 
           return `<div class="py-2 px-4 rounded-full text-xs bg-white border text-gray-600 cursor-pointer font-medium">${cell.getData().stop ? "Finalizada" : '<i data-feather="youtube" class="w-4 h-4 mr-1"></i>'+" En curso"}</div>`
           //return `<div class="flex items-center lg:justify-center ${cell.getData().stop ? "text-theme-20" : "text-green-900"}">${cell.getData().stop ? "Finalizada" : '<i data-feather="youtube" class="w-4 h-4 mr-1"></i>'+" En curso"}</div>`; 
           } 
        },
        { title: "Acciones", maxWidth: 140, field: "actions", responsive: 1, hozAlign: "center",vertAlign: "middle",print: false,download: false, headerSort: false,
          formatter(cell) {
            const a = cash(`<div class="flex lg:justify-center items-center"><a class="flex items-center mr-3" href="javascript:;"><button class="btn btn-sm btn-primary-soft w-24 text-gray-600">Detalles</button></a></div>`);
            cash(a).on("click", function() {  ItemDetails(cell.getData().id) });
            return a[0];
          }
        },
        // print
        { title: "ID Sesión", field: "id", minWidth: 250, visible: false, print: true, download: true },
        { title: "Monitor",   field: "monitor", visible: false, hozAlign: "center", print: true, download: true },
        { title: "Inicio",    field: "start_date", visible: false, print: true, download: true },
        { title: "Fin",       field: "stop_date", visible: false, print: true, download: true },
        { title: "Registros", field: "data.total", visible: false, print: true, download: true },
      ]
    };

    const ItemDetails  = (i) => { cash("#modal-item-details").modal("show"); LoadDetails.value = true; itemDetails.value = i;  setTimeout(() => LoadDetails.value = false, 1000);  };

    const getStats = () => { $table.getStats(tabulator.value, Stats.value, { limit: 150, labelKey: 'monitor', dataKey: "data", dataSubKey: "total", duration: "unix" }); Loading.value = false; };

    const ResetFilters  = () => { 
       filter.field    = filterOpt.value[0].id; filter.type   = filterOperator[0] ? filterOperator[0].id : "like"; filter.value  = ""; 
       tabulator.value = $table.resetFilter(tabulator.value, filter)
       getStats()
    };

    watch( () => props.options, async () => { Object.assign(LayoutOptions.value,props.options); });
    watch( () => DateRange.value, async () => { 
       Loading.value = true;
       tableData.value = await $session.byDateRange(DateRange.value.toString().split(' - '));
       await $table.setData(tabulator.value, tableData.value) //set table data
       ResetFilters()
    });

    onMounted( async () => { 
      
      Loading.value        = true;
      LayoutOptions.value  = Object.assign(props.options, LayoutOptions.value);
      tabulator.value      = await $table.init(tableRef.value, tableComuns(), { printHeader: "<h1>"+itemName.value+"</h1>"})
      tableData.value      = await $session.byDateRange(DateRange.value.toString().split(' - ')); 
      await $table.setData(tabulator.value, tableData.value) //set table data
      Stats.value.total    = tabulator.value.getData().length
      getStats()
     
      filterOperator.value = $table.filterOperators()
      if(PrintBtn.value)PrintBtn.value.addEventListener( 'click', function(event) { event.preventDefault(); $table.print(tabulator.value) })
      if(ExportBtn.value)ExportBtn.value.addEventListener('click', function(event) { event.preventDefault(); $table.exportCsv(tabulator.value, itemName.value+'.csv') })
      if(SearchBtn.value){
        SearchBtn.value.addEventListener('click', function(event) { event.preventDefault();
                          for (const k of Object.keys(filter)){ filter[k] = document.getElementById("tabulator-html-filter-"+k).value; }
                          tabulator.value = $table.filter(tabulator.value, filter)
                          getStats()
                        })
      }
      if(resetBtn.value)
        resetBtn.value.addEventListener('click', function(event) { event.preventDefault();
                        DateRange.value = dayjs().subtract(1, "week").format('DD/MM/YYYY')+" - "+dayjs().format('DD/MM/YYYY')
                        ResetFilters()
                       })
    });
    
    const linkTo = (page,params=false) => {  let p = { dates: {} }; if(params)p.dates=params; router.push({ name: page, params: p });  };

    return {
      Loading,
      tableRef,
      filter,
      filterOpt,
      filterOperator,
      tabulator,
      PrintBtn,
      ExportBtn,
      SearchBtn,
      resetBtn,
      tableData,
      itemDetails,
      LoadDetails,
      LayoutOptions,
      Stats,
      itemName,
      DateRange,
      linkTo
    };
  }
});
</script>
<style> .StatsBoxSession{ min-height: 170px;} </style>