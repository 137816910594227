import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Toastify from "toastify-js";
import cash from "cash-dom";
import moment from 'moment';

dayjs.extend(duration);

const helpers = {
  cutText(text, length) {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format);
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time) {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(time) {
    const startDate = dayjs(
      dayjs()
        .format("YYYY-MM-DD HH:mm:ss")
        .toString()
    );
    const endDate = dayjs(
      dayjs(time)
        .format("YYYY-MM-DD HH:mm:ss")
        .toString()
    );

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds
    };
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from, to, length) {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }
    return numbers;
  },
  isObject(obj){ return typeof obj == "object"  },
  formatScore(value) { let val = (value/1).toFixed(0).replace('.', ','); return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") },
  Notification (not) {
    Toastify({
       node: cash(not).clone().removeClass("hidden")[0],
       duration: 1500,
       newWindow: true,
       gravity: "top",
       position: "right",
       stopOnFocus: true
       }).showToast();
   },
   NotificationTxt (not) {
    let text            = ""; if(not.text)text = not.text;
    let transition      = "all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)"; if(not.transition)transition = not.transition;
    let backgroundColor = "linear-gradient(to right, #fff, #fff)"; if(not.backgroundColor)backgroundColor = not.backgroundColor;
    let duration        = 2500; if(not.duration)duration = not.duration;
    Toastify({
       text: text,
       transition: transition,
       backgroundColor: backgroundColor,
       duration: duration,
       newWindow: true,
       gravity: "top",
       position: "right",
       stopOnFocus: true,
       className: "toastify-content flex",
       }).showToast();
   },
   getObjectProperty(objs,type=false) {
    let Objects = {}
    for (const obj of objs) {
      let path      = ""
      let props     = []
      let methods   = []
      let functions = []
      let actions   = []
      let componts  = []
      let include   = true
      if(type && type!=obj.type)include  = false
      /*if(c.id=='helper'){ console.log(c.component) }*/
      Object.getOwnPropertyNames(obj.component).sort().forEach(function(m) { 
        if(m=='__file')path = obj.component[m]
        if(m=='props')props.push(obj.component[m])
        if(m=='methods')methods.push(obj.component[m])
        if(m=='actions')actions.push(obj.component)
        if(typeof obj.component[m] == 'function' && m!='render' && m!='setup')functions.push(m);
        if(typeof m == 'function')functions.push(m);
        if(m=='components')componts.push(obj.component[m])
      });
      if(include){
        Objects[obj.id] = [];
        let newItem = { id: obj.id, path: path, props: props , methods: methods, functions: functions, component: obj.component, type: obj.type, actions: actions, components: componts }
        if(obj.link)newItem.link = obj.link;
        Objects[obj.id].push(newItem)
      }
    }
    return Objects;
   },
   getIcons(){ return ['ActivityIcon','AlignJustifyIcon','BoxIcon','BookmarkIcon','CpuIcon','GithubIcon','ClockIcon','DatabaseIcon','Edit3Icon','EditIcon','FlagIcon','GridIcon','HashIcon','ShoppingCartIcon','ToggleLeftIcon','PieChartIcon','TerminalIcon','TrendingUpIcon','TriangleIcon','UploadCloudIcon','UserIcon','UsersIcon']},
   getBrowsers(){ return ['chrome','edge','mozilla','opera','safari'] },
   getTimestampDate(d,type=false){
    let date = new Date(d); 
     if(type){
      if(type=='full')return moment(date).format("DD/MM/YYYY HH:mm:ss"); 
      else if(type=='date'){
        if(moment().format("DD/MM/YYYY")==moment(date).format("DD/MM/YYYY"))return "Hoy"
        return moment(date).format("DD/MM/YYYY")
      }
      else if(type=='time')return moment(date).format("HH:mm:ss")
      else return moment(date).format("DD/MM/YYYY HH:mm:ss");
     }else{ return moment(date).format("DD/MM/YYYY HH:mm:ss"); } 
   },
   getUnixDate(d){
    return moment.unix(d/1000).format("DD MMM YYYY hh:mm a")
   },
   orderParams(o){ return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); },
   sortArray(obj){ 
    return obj.sort((a,b)=> (a > b ? 1 : -1)) 
   },
   sortObject(obj,key){ 
    return obj.sort((a,b)=> (a[key] > b[key] ? 1 : -1)) 
   },
   dateRangeLabel(d){
    let date = d.toString().split(' - ')
    if(date[0] && date[1] && date[0]==date[1])return date[0]
    return d; 
  }
};

const install = app => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
