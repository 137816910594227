<template>
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-5 mt-5" v-if="Loading">
        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-20"><LoadingIcon icon="grid" class="w-20 h-20" /> </div>
    </div>
    <div v-else>
      <div class="flex items-center justify-center lg:justify-start mt-2" v-if="Monitors">
          <div class="intro-y flex items-center h-10">
            <div class="ml-auto mt-3">
              <select class="form-select bg-transparent border-black border-opacity-10 dark:bg-transparent dark:border-opacity-100 dark:border-dark-5 mx-auto sm:mx-0 py-1.5 px-3 w-28 pr-10 cursor-pointer"
               @change="ViewMonitor($event)">
                <option :value="m.id" v-for="m in Monitors" v-bind:key="m.id">{{ m.id }}</option>
              </select>
              <SlidersIcon class="w-5 h-5 ml-3 cursor-pointer" @click="linkTo('side-menu-settings')" v-if="root" />
            </div>
          </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-2">
        <div class="col-span-12 xl:col-span-12 xxl:col-span-12 z-10"><MonitorControls :monitor="monitor.id" ref="MonitorControls" /></div>
        <div class="col-span-12 xl:col-span-4 xxl:col-span-3 z-10"><GlobalCounter :monitor="monitor.id" ref="GlobalCounter" /></div>
        <div class="col-span-12 xl:col-span-8 xxl:col-span-5 z-10"><MonitorPreview :monitor="monitor.id" ref="MonitorPreview"  /></div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onBeforeMount, watch, computed  } from "vue";
import { useRouter } from "vue-router";
//import firebase from 'firebase/app'
import { useStore } from "@/store";
import { _firebase as $_firebase } from "@/model/firebase";

import GlobalCounter from "@/components/global-counter/Main.vue";
import MonitorControls from "@/components/monitor-controls/Main.vue";
import MonitorPreview from "@/components/monitor-preview/Main.vue";

export default defineComponent({
  components: {
    GlobalCounter,
    MonitorControls,
    MonitorPreview
  },
  setup() {
    const Loading       = ref(true);
    const Monitors      = ref({});
    const monitor       = ref({});
    const router        = useRouter();
    const store         = useStore();  
    const User          = computed(() => store.state.main.User);
    const root          = ref(false);
    
    const initMonitors = () => {
      $_firebase.firestore().collection('monitor').get()
      .then(snapshot => {
        let first = false;
        snapshot.forEach(doc => {
          let item = doc.data()
          item.id  = doc.id
          Monitors.value[doc.id] = item;
          if(!first){
            monitor.value  = {  id: item.id, } 
            Loading.value = false
            first = true;
          }
        });
      });
    }

    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    const linkTo = (page) => {  router.push({ name: page });   };
    onBeforeMount( () => { initMonitors();  });
    onMounted( () => {  if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    return {
      Loading,
      Monitors,
      monitor,
      linkTo,
      User,
      root
    };
  },
  methods: {
     ViewMonitor: function(event){
        if (event) event.preventDefault()
        this.monitor = {  id: event.target.value, } 
     }   
  }
});
</script>