
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import config from '../etc/rosepetal.json';

const firebaseConfig = config.firebaseConfig;

const firebaseApp    = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({ timestampsInSnapshots: true })
export const db = firebaseApp.firestore();

const _firebase = {
    getConfig(){  return firebaseConfig },
    setHost(){
        if (location.hostname.includes(config.localhost)) {
            const auth = firebase.auth();
            const rdb = firebase.database();
            const functions = firebase.functions();
            db.useEmulator(config.localhost, 8080);
            auth.useEmulator("http://"+config.localhost+":9099");
            rdb.useEmulator(config.localhost, 9000);
            functions.useEmulator(config.localhost, 5001);
        }
    },

    firebase(){ return firebase; },
    firestore(){
     let db = firebase.firestore()     
     return db;
    },
    database(){
     let rdb = firebase.database();
     return rdb;
    },
     auth(){
     let auth = firebase.auth();
     return auth;
    },
    storage(){
     const storage = firebase.storage();
     return storage;
    },
    functions(){
      const functions = firebase.functions();
      return functions;
    },
};

const install = app => {
  app.config.globalProperties.$firebase = _firebase;
};

export { install as default, _firebase as _firebase };
