<template>
  <div>
    <div class="grid chat grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-5 xxl:col-span-3">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="chat__tabs nav nav-tabs justify-center" role="tablist">
              <a
                id="monitors-tab"
                data-toggle="tab"
                data-target="#monitors"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center" 
                role="tab"
                aria-controls="monitors"
                aria-selected="true"
                ><MonitorIcon class="w-4 h-4 mr-1" />Monitores
                </a>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div id="monitors" class="tab-pane active" role="tabpanel" aria-labelledby="monitors-tab">
            <div class="scrollbar-hidden pr-1 pt-1" :class="monitors ? '' : 'hidden'">
              <div class="cursor-pointer box relative flex items-center p-5 mt-2" v-for="(monitor) in monitors" :key="monitor.id" :class="monitor.id==curMonitor ? 'bg-gray-100' : ''">
                <div class="w-12 h-12 flex-none image-fit mr-1">
                  <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 text-center pt-1.5 hover:bg-theme-17" @click="ChangeMonitor(monitor.id)" 
                       :class="monitor.id==curMonitor ? 'bg-theme-17' : 'bg-theme-21'"
                       role="button" aria-expanded="false">
                    <span class="text-white text-2xl">{{  monitor.id.charAt(0) }}</span>
                  </div>
                  <div class="w-3 h-3 absolute right-0 bottom-0 rounded-full border-2 border-white z-10" :class="MonStatus[monitor.id] ? 'bg-theme-10' : 'bg-red-500'"></div>
                </div>
                <div class="ml-2 overflow-hidden" @click="ChangeMonitor(monitor.id)">
                  <div class="flex items-center">
                    <a class="font-medium truncate" :class="monitor.id==curMonitor ? 'text-theme-17' : ''">{{ monitor.id }}</a>
                  </div>
                </div>
                <div class="dropdown ml-auto">
                  <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer" @click="ChangeMonitor(monitor.id)">
                        <EditIcon class="w-4 h-4 mr-2" />Editar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-7 xxl:col-span-9">
        <!-- Monitors -->
        <div v-for="(monitor) in monitors" :key="monitor.id">
            <div class="intro-y box w-full" v-if="monitor.id == curMonitor">
               <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class=" text-base mr-auto"><MonitorIcon class="w-4 h-4 mr-1" /> Monitor: {{monitor.id}} </h2>
                <div class="ml-auto">
                  <button v-if="!MonRules" type="button" class="btn btn-primary py-2 px-4 mr-3" @click="Rules(monitor.id)"><FilterIcon class="w-4 h-4 mr-1" />Reglas de uso</button>
                  <button v-if="!MonDemo"  type="button" class="btn btn-primary py-2 px-4" @click="DemoSet(monitor.id)"><VideoIcon class="w-4 h-4 mr-1" />Demo</button>
                </div>
              </div>
              <div class="preview p-5 pt-2">
                 <div v-if="MonRules"><MonitorRules :monitor="MonRules" ref="MonitorRules" /></div>
                 <div v-else-if="MonDemo"><MonitorDemo :monitor="MonDemo" ref="MonitorDemoSet" /></div>
                 <div v-else>
                  <div class="text-base pb-2 font-medium">Detalles</div>
                  <table class="table table--sm mb-5">
                      <tbody>
                          <tr class="hover:bg-gray-200"><td class="border">Nombre</td><td class="border font-bold">{{monitor.id}}</td></tr>
                          <tr class="hover:bg-gray-200">
                            <td class="border">Estado</td>
                            <td class="border font-normal"><span v-if="MonStatus[monitor.id]" class="text-theme-10"><PlayCircleIcon class="w-4 h-4 mr-1" />Activo</span><span v-else class="text-red-600"><StopCircleIcon class="w-4 h-4 mr-1" />Detenido</span></td>
                          </tr>
                          <tr class="hover:bg-gray-200"><td class="border">Api Url</td><td class="border font-normal">{{monitor.api_url}}</td></tr>
                      </tbody>
                  </table>
                  <div class="text-base pb-2 font-medium">Predicción</div>
                  <table class="table table--sm mb-5">
                      <tbody>
                          <tr class="hover:bg-gray-200"><td class="border">Rest</td><td class="border font-normal">{{monitor.prediction.rest}}</td></tr>
                      </tbody>
                  </table>
                  <div class="text-base pb-2 font-medium" v-if="MonSessions[monitor.id]">Última sesión</div>
                  <table class="table table--sm" v-if="MonSessions[monitor.id]">
                      <tbody>
                          <tr class="hover:bg-gray-200"><td class="border">ID</td><td class="border font-normal">{{MonSessions[monitor.id].id}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">Fecha</td><td class="border font-normal">{{MonSessions[monitor.id].start_date}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">Duración</td>
                            <td class="border font-normal" v-if="MonSessions[monitor.id].duration">
                                {{MonSessions[monitor.id].duration.hours}}h {{MonSessions[monitor.id].duration.minutes}}m {{MonSessions[monitor.id].duration.seconds}}s
                            </td>
                          </tr>
                          <tr class="hover:bg-gray-200"><td class="border">Predicition rest</td><td class="border font-normal truncate">{{MonSessions[monitor.id].prediction_rest}}</td></tr>
                          <tr class="hover:bg-gray-200"><td class="border">Usuario</td><td class="border font-normal">{{MonSessions[monitor.id].uid}}</td></tr>
                      </tbody>
                  </table>
                 </div>
              </div>
            </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { monitor as $monitor } from "@/model/monitor";
import { session as $session } from "@/model/session";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";

import MonitorRules from "@/components/monitor-rules/Main.vue";
import MonitorDemo  from "@/components/monitor-demo/Main.vue";

export default defineComponent({
  components: {
    MonitorRules,
    MonitorDemo
  },
  created () {
    this.helper  = $h
  },  
  setup() {
 
    const monitors     = ref([])
    const curMonitor   = ref()
    const MonStatus    = ref({})
    const MonSessions  = ref({})
    const MonRules     = ref(false)
    const MonDemo      = ref(false)

    const ChangeMonitor = async (m) => { MonRules.value = false; MonDemo.value = false; curMonitor.value = m; await getStatus() };

    const getStatus     = async ()  => {
      if(monitors.value){
        for (let i = 0; i < monitors.value.length; i++) {
          if(monitors.value[i].id){
            MonStatus.value[monitors.value[i].id]   = await $monitor.isPlay(monitors.value[i].id);
            MonSessions.value[monitors.value[i].id] = await $session.getLast({ uid : useStore().state.main.User.uid , monitor: monitors.value[i].id });
          }
        }
      }
     };
    
    const Rules    = (m) => { MonRules.value = m; MonDemo.value = false; };
    const DemoSet  = (m) => { MonDemo.value = m;  MonRules.value = false;};
  
    onMounted( async () => { 
      monitors.value = await $monitor.get(); if(monitors.value[0])curMonitor.value = monitors.value[0].id 
      await getStatus()
    });

    return {
      monitors,
      curMonitor,
      MonStatus,
      MonSessions,
      MonRules,
      MonDemo,
      ChangeMonitor,
      Rules,
      DemoSet
    };
  },
  methods:{
    
  }
});
</script>
