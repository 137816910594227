<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
          <div class="intro-y box mt-5 lg:mt-0">
            <div class="relative flex items-center p-5">
              <div class="w-10 h-10 image-fit"><BookmarkIcon class="w-10 h-10"  /></div>
              <div class="ml-2 mr-auto">
                <div class="font-medium text-base">Tags</div>
                <div class="text-gray-600">{{ tags.length }} elementos</div>
              </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5 pt-0 mh-menu">
              <div v-for="(tag) in tags" :key="tag.id" >
                <a class="flex items-center mt-3 cursor-pointer" :class="TagActive(tag.id)" @click="ChangeTag(tag.id)">{{ tag.name }}</a>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-7 xxl:col-span-8">
         <div v-for="(tag) in tags" :key="tag.id">
            <div class="intro-y box" v-if="tag.id == curTag">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base mr-auto">{{ tag.name }}</h2>
                <div class="form-inline w-8 h-8" :style="{'background-color':colors[tag.id].color}" v-if="colors[tag.id].color"><span class="text-white pl-3">{{colors[tag.id].tag}}</span></div>
                </div>
                <div id="form-validation" class="p-5">
                <div class="preview pb-5">
                    <form :id="tag.id" class="validate-form" :ref="'form-'+tag.id">
                    <div class="input-form">
                        <label class="form-label">Nombre</label>
                        <input type="text" class="form-control" id="name" :value="tag.name" disabled />
                    </div> 
                    <div class="input-form mt-3">
                        <label class="form-label">Confianza (modelo)</label>
                        <input type="text" class="form-control" id="model_confidence" :value="tag.model_confidence" disabled />
                    </div> 
                    <div class="input-form mt-3">
                        <label class="form-label">Imágenes (demo)</label>
                        <input type="text" class="form-control" id="demo_images" :value="tag.demo_images" disabled />
                    </div> 
                    </form>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
import { tag as $tag } from "@/model/tag";

export default defineComponent({
  setup() {
    
    const tags   = ref([])
    const colors = ref([])
    const curTag = ref()
    const TagActive = (t) => { if(t==curTag.value)return "dark:text-white text-theme-12 font-medium" };
    const ChangeTag = (t) => { curTag.value = t;};

    onBeforeMount( async () => { 
      tags.value   = await $tag.list();
      colors.value = await $tag.getColors();
      if(tags.value[0])curTag.value = tags.value[0].id  
     });

    onMounted( async () => { 
       
    });

    return {
     tags,
     curTag,
     TagActive,
     ChangeTag,
     colors
    };
  }
});
</script>
