import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";
import config from '../etc/rosepetal.json';

const functions = $_firebase.firebase().app().functions(config.firebaseRegion);

const models = {
  async get(){
      let models = []
      const action = functions.httpsCallable('api/model/list');
      await action({}).then( async response => { 
        for (let i = 0; i < response.data.length; i++) {
          let m = response.data[i]
          m.dataset = await this.getDataset(m.datasetId)
          models.push(m) 
        }
      }).catch( async (error) => {  console.log(error) });
      return models;
  },
  async getEvaluation(modelID){
    let evaluation = []
    const action = functions.httpsCallable('api/model/evaluation/model_id/'+modelID);
    await action({}).then( async response => {  
      if(response.data){
        for (let i = 0; i < response.data.length; i++) {
           let e = response.data[i];
           evaluation.push(e) 
        }
      }  
    }).catch( async (error) => {  console.log(error) });
    if(evaluation.length)evaluation = $h.sortObject(evaluation,'displayName') 
    return evaluation
  },
  async getEvaluationDatamatrix(modelEvaluation){
    let datamatrix = null
    for (let i = 0; i < modelEvaluation.length; i++) {
     if(modelEvaluation[i].metrics && modelEvaluation[i].metrics.confusionMatrix){
       let dm = modelEvaluation[i].metrics.confusionMatrix
       if(dm.row){
        let rowsCount = [] 
        dm.row.forEach (function(num){
           let count = 0
           for (let n = 0; n < num.exampleCount.length; n++) { count += num.exampleCount[n];  }
           rowsCount.push(count)
        });
        dm.rowsCount = rowsCount;
        datamatrix = dm;
       }
     }
    } 
    return datamatrix
  },
  async getDataset(datasetID=false){
    let datasets = []
    let ApiCall = 'api/model/dataset'
    if(datasetID)ApiCall += '/dataset_id/'+datasetID
    const action = functions.httpsCallable(ApiCall);
    await action({}).then( response => { datasets = response.data  }).catch( async (error) => {  console.log(error) });
    if(datasetID && datasets[0])return datasets[0];
    return datasets
  },
  async addDataset(displayName,type){
    let dataset = []
    const action = functions.httpsCallable('api/model/create/dataset/'+displayName+'-'+type);
    await action({}).then( response => { dataset = response.data  }).catch( async (error) => {  console.log(error) });
    return dataset;
  }
};

const install = app => {
  app.config.globalProperties.$models = models;
};

export { install as default, models as model };
