<template>
  <div>
    <div v-if="!curUser">
      <form class="validate-form" @submit.prevent="create($event)" :ref="'create-user'">
        <h2 class="font-medium text-base text-gray-600 mr-auto mb-4">Nuevo usuario</h2>
        <div class="grid grid-cols-12 gap-2">
            <input name="displayName" type="text" class="form-control col-span-12 lg:col-span-2" placeholder="Nombre" aria-label="Nombre" required />
            <input name="email" type="email" class="form-control col-span-12 lg:col-span-3 xxl:col-span-2" placeholder="Email" aria-label="Email" required autocomplete="off" />
            <input name="password" type="password" class="form-control col-span-12 lg:col-span-3 xxl:col-span-2" placeholder="Contraseña" aria-label="Contraseña" required autocomplete="off"  />
            <div class="col-span-12 lg:col-span-2"><button type="submit" class="btn btn-outline-primary w-24 h-9 mt-0.5">Nuevo</button></div>
        </div>
      </form>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="col-span-12">
          <h2 class="font-medium text-base text-gray-600 mr-auto mb-4" v-if="users.length" >Todos los usuarios ({{ users.length }})</h2>
          <div class="grid grid-cols-12 gap-6">
            <div v-for="(user) in users" :key="user.uid" class="intro-y col-span-12 lg:col-span-6 xxl:col-span-3">
              <div class="box">
                <div class="flex items-start px-5 pt-5 pb-5">
                  <div class="w-full flex flex-col lg:flex-row items-center">
                    <div @click="ChangeUser(user.uid)" class="w-12 h-12 image-fit rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-theme-17 text-center pt-1.5">
                      <span class="text-white text-2xl">{{ user.displayName.charAt(0) }}</span>
                    </div>
                    <div class="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                      <a class="font-medium">{{ user.displayName }}</a>
                      <div class="text-gray-600 text-xs mt-0.5">{{ user.email }}</div>
                      <div class="text-gray-600 text-xs mt-0.5" v-if="user.role">{{ user.role.name }}</div>
                    </div>
                  </div>
                  <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
                    <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" /></a>
                    <div class="dropdown-menu w-40">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a @click="ChangeUser(user.uid)" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                          <Edit2Icon class="w-4 h-4 mr-2" /> Editar
                        </a>
                        <a @click="deleteUser('delete-user-'+user.uid)" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                          <TrashIcon class="w-4 h-4 mr-2" /> Eliminar
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- Delete user modal -->  
                  <div :id="'delete-user-'+user.uid" class="modal" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog">
                          <div class="modal-content">
                              <div class="modal-body p-0">
                                  <div class="p-5 text-center">
                                      <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                                      <div class="text-2xl mt-5">Eliminar usuario</div>
                                      <div class="text-red-600 mt-2">{{ user.email }}</div>
                                      <div class="text-gray-600 mt-2 text-sx">{{ user.uid }}</div>
                                      <div class="text-gray-600 mt-2">¿Está seguro que desea eliminar el usuario? <br />Esta acción és irreversible.</div>
                                  </div>
                                  <div class="px-5 pb-8 text-center">
                                      <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">Cancelar</button>
                                      <button type="button" class="btn btn-danger w-24" @click="this.delete(user.uid)">Eliminar</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-12 xxl:col-span-6">
          <div v-for="(user) in users" :key="user.uid">
              <div class="intro-y box" v-if="user.uid == curUser">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Perfil de usuario</h2>
                    <div class="ml-auto"><span class="form-help text-gray-400 pl-3">{{ user.uid }}</span></div>
                  </div>
                  <div id="form-validation" class="p-5 pt-3">
                    <!-- Form Update -->
                    <form class="validate-form" @submit.prevent="update(user.uid,$event)" :ref="'update-user-'+user.uid">
                      <div class="preview">
                            <div class="input-form">
                              <label class="form-label w-full flex flex-col sm:flex-row">Nombre</label>
                              <input type="text" name="displayName" class="form-control"  :value="user.displayName" />
                            </div>
                            <div class="input-form mt-3">
                                <label class="form-label w-full flex flex-col sm:flex-row">
                                    Email
                                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-green-600" v-if="user.emailVerified">Verificado</span>
                                    <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-red-600" v-else>No verificado</span>
                                </label>
                                <input type="text" class="form-control" disabled :value="user.email" />
                            </div> 
                            <div class="input-form mt-3" v-if="user.role">
                              <label class="form-label w-full flex flex-col sm:flex-row">Tipo de cuenta</label>
                              <input type="text" class="form-control" disabled :value="'('+user.role.role_id+') '+user.role.name" />
                            </div>
                          <!-- Notifications -->
                          <div id="success-notification-content" class="toastify-content hidden flex">
                            <CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">Su perfil ha sido actualizado correctamente</div></div>
                          </div>
                      </div>
                      <div class="text-left mt-5 border-t border-gray-200 pt-5">
                        <button type="submit" class="btn btn-primary w-24">Guardar</button>
                        <button type="button" class="btn btn-outline-secondary w-24 ml-2" @click="ChangeUser(false)">Cancelar</button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, getCurrentInstance } from "vue";
import { user as $user } from "@/model/user";
import cash from "cash-dom";

export default defineComponent({
  setup() {
    const users      = ref([])
    const curUser    = ref()
    const UserActive = (m) => { if(m==curUser.value)return "dark:text-white text-theme-12 font-medium" };
    const ChangeUser = (m) => { curUser.value = m;};
    const Parent     = ref()
    const internalInstance = getCurrentInstance()
    Parent.value = internalInstance; 


    const deleteUser = (u) => { cash("#"+u).modal("show");  };

    const reLoad =  () => { Parent.value.parent.ctx.ReloadTab('ManageUsers')  }

    onMounted( async () => { users.value = await $user.list(); });

    return {
      users,
      curUser,
      UserActive,
      ChangeUser,
      deleteUser,
      reLoad
    };
  },
  methods:{
    getLayoutOpt: function(){ return ['sidebar','table'] },
    update: async function(uid,event){
      if (event) event.preventDefault()
      let formID = "update-user-"+uid; 
      if(this.$refs[formID].length){
        const userdata = { uid: uid };
        for (let i = 0; i < this.$refs[formID].length; i++) { if(!this.$refs[formID][i].disabled && this.$refs[formID][i].name)userdata[this.$refs[formID][i].name] = this.$refs[formID][i].value; }  
        await $user.update(userdata, true).then( this.reLoad() );
      }
    },
    create: async function(event){
      if (event) event.preventDefault()
      let formID = "create-user"; 
      if(this.$refs[formID].length){
        const userdata = {  };
        for (let i = 0; i < this.$refs[formID].length; i++) { 
          if(!this.$refs[formID][i].disabled && this.$refs[formID][i].name)userdata[this.$refs[formID][i].name] = this.$refs[formID][i].value; 
        }  
        await $user.create(userdata, true).then( this.reLoad() );
      }
    },
    delete: async function(uid){
       await $user.delete(uid,true).then(this.reLoad())
       cash('#delete-user-'+uid).modal("hide"); 
       cash('#delete-user-'+uid).removeClass('show')
    }
  }
});
</script>
