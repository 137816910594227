//import firebase from 'firebase/app'

import { _firebase as $_firebase } from "@/model/firebase";

const layouts = {
    async getMainMenu(){
      let mon = $_firebase.firestore().collection('view').doc('layout')
      const snapshot = await mon.get();
      let item = snapshot.data(); item.id = snapshot.id
      let mainMenu  = JSON.parse(item.main_menu)
      return mainMenu;
      //return [{icon: "MonitorIcon",pageName: "side-menu-monitor",title: "Monitor"},{icon: "CpuIcon",pageName: "",title: "Modelo",subMenu: [{icon: "EyeIcon",pageName: "side-menu-training",title: "Entreno"},]},"devider",{icon: "CodeIcon",pageName: "",title: "Desarrollo",role_id: "2",subMenu: [{icon: "LayersIcon",pageName: "side-menu-log",title: "Registro"},]}]   
    },
};

const install = app => {
  app.config.globalProperties.$layouts = layouts;
};

export { install as default, layouts as layout };
