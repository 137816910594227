<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- Sidebar Menu -->
      <div class="col-span-12 lg:col-span-3 xxl:col-span-2 flex lg:block flex-col-reverse"><ProfileMenu :tab="initTab" ref="ProfileMenuRef" /></div>
      <!-- Welcome -->
      <div class="col-span-12 lg:col-span-9 xxl:col-span-9" v-if="initTab=='welcome'">
        <div class="items-center p-5 w-full">
          <h2 class="text-2xl text-gray-700 dark:text-gray-600 font-medium leading-none">Bienvenido,</h2>
          <div class=" grid grid-cols-12 gap-6 mt-5 z-40 xxl:z-10">
            <div class="col-span-12 xxl:col-span-4 relative z-10">
              <ManageEvents :uid="User.uid" view="sidebar" limit="5" ref="ManageEventsSidebarRef" />
            </div>    
          </div> 
        </div>
      </div>
      <!-- Profile User -->
      <div class="col-span-12 lg:col-span-4 xxl:col-span-4" v-if="initTab=='profile'">
          <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">Información personal</h2>
            </div>
            <div id="form-validation" class="p-5">
              <div class="preview">
                <!-- Form Account -->
                <form class="validate-form" @submit.prevent="saveProfile">
                  <div class="input-form mt-0">
                      <label class="form-label">Identificador</label>
                      <input type="text" class="form-control" disabled :value="User.uid" />
                  </div>   
                  <div class="input-form mt-3">
                      <label class="form-label w-full flex flex-col sm:flex-row">
                          Email
                          <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-green-600" v-if="User.emailVerified">Verificado</span>
                          <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-red-600" v-else>No verificado</span>
                      </label>
                      <input type="text" class="form-control" disabled :value="User.email" />
                  </div> 
                  <div class="input-form mt-3">
                    <label for="validation-form-1" class="form-label w-full flex flex-col sm:flex-row">
                      Nombre <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">Requerido</span>
                    </label>
                    <input
                      id="validation-form-1"
                      v-model.trim="validate.name.$model"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'border-theme-24': validate.name.$error }"
                      placeholder="Su nombre de usuario"
                    />
                  </div>
                  <div class="input-form mt-3" v-if="User.role">
                    <label class="form-label w-full flex flex-col sm:flex-row">Tipo de cuenta</label>
                    <input type="text" class="form-control" disabled :value="'('+User.role.role_id+') '+User.role.name" />
                  </div>
                  <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="Loading"> 
                      <LoadingIcon icon="three-dots" class="w-12 h-12 mt-2 mb-2" />
                  </div>
                  <div class="input-form" v-else>
                      <button type="submit" class="btn btn-primary mt-5">Guardar</button>
                      <button type="button" class="btn btn-secondary mt-5 ml-2" v-if="!User.emailVerified" @click="verifyEmail()">Verificar email</button>
                  </div>
                </form>
                <!-- Notifications -->
                <div id="success-notification-content" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">Su perfil ha sido actualizado correctamente</div></div>
                </div>
                <div id="failed-notification-content" class="toastify-content hidden flex">
                  <XCircleIcon class="text-theme-24" /> <div class="ml-4 mr-4"><div class="font-medium">Por favor revise los campos requeridos.</div></div>
                </div>
                <div id="failed-notification-db" class="toastify-content hidden flex">
                  <XCircleIcon class="text-theme-24" /> <div class="ml-4 mr-4"><div class="font-medium">No se ha podido completar la acción. Inténtelo de nuevo.</div></div>
                </div>
                <div id="success-notification-verify" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">Se ha enviado un correo a su cuenta {{ User.email }} para su verificación.</div></div>
                </div>
                <div id="success-notification-password" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">Su contraseña ha sido actualizada</div></div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Password-->
      <div class="col-span-12 lg:col-span-4 xxl:col-span-4" v-if="initTab=='password'">
          <div class="intro-y box">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">Contraseña</h2>
            </div>
            <div id="form-validation" class="p-5">
              <div class="preview">
                <!-- Form Password -->
                <form class="validate-form" @submit.prevent="UpdatePass">
                  <div class="input-form mt-0">
                    <label for="validation-form-1" class="form-label w-full flex flex-col sm:flex-row">
                    Contraseña nueva <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">Requerido</span>
                    </label>
                    <input
                      id="validation-form-1"
                      v-model.trim="validatePass.password.$model"
                      type="password"
                      name="password"
                      class="form-control"
                      :class="{ 'border-theme-24': validatePass.password.$error }"
                      placeholder="Contraseña nueva"
                    />
                  </div>
                  <div class="input-form mt-3">
                    <label for="validation-form-2" class="form-label w-full flex flex-col sm:flex-row">
                    Confirme la nueva contraseña <span class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">Requerido</span>
                    </label>
                    <input
                      id="validation-form-2"
                      v-model.trim="validatePass.repeatPassword.$model"
                      type="password"
                      name="repeatPassword"
                      class="form-control"
                      :class="{ 'border-theme-24': validatePass.repeatPassword.$error }"
                      placeholder="Confirme la nueva contraseña"
                    />
                  </div>
                  <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="Loading"> 
                      <LoadingIcon icon="three-dots" class="w-12 h-12 mt-2 mb-0" />
                  </div>
                  <div class="input-form" v-else>
                      <button type="submit" class="btn btn-primary mt-5">Cambiar la contraseña</button>
                  </div>
                </form>
                <!-- Notifications -->
                <div id="success-notification-password" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10" /> <div class="ml-4 mr-4"><div class="font-medium">Su contraseña ha sido actualizada</div></div>
                </div>
                <div id="failed-notification-password" class="toastify-content hidden flex">
                  <XCircleIcon class="text-theme-24" /> <div class="ml-4 mr-4"><div class="font-medium">Las contraseñas no coinciden.</div></div>
                </div>
                <div id="failed-notification-password-db" class="toastify-content hidden flex">
                  <XCircleIcon class="text-theme-24" /> <div class="ml-4 mr-4"><div class="font-medium">No se ha podido completar la acción. Inténtelo de nuevo.</div></div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- Events-->
      <div class="col-span-12 lg:col-span-9 xxl:col-span-10" v-if="initTab=='ManageEvents'"><ManageEvents :uid="User.uid" ref="ManageEventsRef" /></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref,  onMounted, watch, toRefs } from "vue";
import { required, maxLength, minLength,   } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import { event as $event } from "@/model/events";
//import { user as $user } from "@/model/user";

import ProfileMenu  from "@/components/profile-menu/Main.vue";
import ManageEvents from "@/components/manage-events/Main.vue";


import { _firebase as $_firebase } from "@/model/firebase";

export default defineComponent({
  components: {
    ProfileMenu,
    ManageEvents
  },
  setup() {

     const Loading = ref(false);   
     const store   = useStore();   
     const User    = computed(() => store.state.main.User);
     const CurUser = $_firebase.auth().currentUser;
     const initTab = ref('welcome'); 
     const ProfileMenuRef = ref(null);   
    
     const formData = reactive({
      name:  store.state.main.User.displayName,
      phone: store.state.main.User.phoneNumber
     });

     const rules = {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(15)
      }
     };

     const formPass = reactive({
      password:  '',
      repeatPassword: ''
     });

     const rulesPass = {
      password: {
        required,
        minLength: minLength(6)
      },
      repeatPassword: {
        required,
        minLength: minLength(6)
      }
     };
     
    const validate      = useVuelidate(rules, toRefs(formData));
    const validatePass  = useVuelidate(rulesPass, toRefs(formPass));

    const saveProfile = async () => {
      Loading.value = true;  
      validate.value.$touch();
      if (validate.value.$invalid) { $h.Notification('#failed-notification-content');
      }else { 
        CurUser.updateProfile({
            displayName: validate.value.name.$model,
        }).then(async function() {
            await $event.saveEvent('user.saveprofile',{ uid: User.value.uid }, false)
            $h.Notification('#success-notification-content'); Loading.value = false;  
        }).catch(function() {
            $h.Notification('#failed-notification-db'); Loading.value = false;  
        });
      }
    };

    const verifyEmail = async () => {
      Loading.value = true;  
      CurUser.sendEmailVerification().then( async function() {
         await $event.saveEvent('user.sendverifyemail',{ uid: User.value.uid }, false)
         $h.Notification('#success-notification-verify'); Loading.value = false; 
      }).catch(function() {
         $h.Notification('#failed-notification-db'); Loading.value = false;  
      });
    };

    const UpdatePass = async () => {
      Loading.value = true;  
      validatePass.value.$touch();  
      if (validatePass.value.$invalid)return;
      if(validatePass.value.password.$model!=validatePass.value.repeatPassword.$model){ 
          $h.Notification('#failed-notification-password'); Loading.value = false; 
      }else { 
          CurUser.updatePassword(validatePass.value.password.$model).then(async function() {
              await $event.saveEvent('user.updatepass',{ uid: User.value.uid }, false)
              $h.Notification('#success-notification-password');   Loading.value = false; 
          }).catch(function() {
              $h.Notification('#failed-notification-password-db'); Loading.value = false; 
         });
      }
    };

    onMounted( () => { 
      watch(() => ProfileMenuRef.value.CurTab, (CurTab) => { initTab.value = CurTab;  })
    });

    return {
      Loading,
      User,
      validate,
      validatePass,
      saveProfile,
      verifyEmail,
      UpdatePass,
      initTab,
      ProfileMenuRef
    };

  }
});
</script>
