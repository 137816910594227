<template>
   <div>
   <div class="report-box-2 intro-y" v-if="status[Mon.id] && Mon.tagname">
        <div class="box p-5 pt-4">
          <div class="flex items-center"><ImageIcon class="w-6 h-6 pr-2" />Última lectura</div>
          <div class="border-b border-gray-200 flex pb-2 mt-1">
            <div class="text-gray-600 dark:text-gray-600 text-xs">Imagen: <span class="font-medium">{{ Mon.key }}</span></div>
            <div class="text-gray-600 dark:text-gray-600 text-xs ml-auto">{{ Mon.date }}</div>
          </div>
          <div class="mt-2">
            <div class="grid grid-cols-12 gap-6">  
              <div class="col-span-12 xl:col-span-4 xxl:col-span-4 z-10">
                 <div class="text-gray-600 dark:text-gray-600 text-xs">Categorización</div>
                 <div class="text-lg font-medium monitor-txt">{{ Mon.tagname }}</div>  
                 <div class="text-gray-600 dark:text-gray-600 text-xs" v-if="root">Salida</div>
                 <div class="text-lg font-medium monitor-output monitor-txt" v-if="root"><CornerRightUpIcon class="w-6 h-6 pr-1" /> {{ Mon.output }}</div>  
                 <div class="text-gray-600 dark:text-gray-600 text-xs pt-2" v-if="root">Tiempo de predicción</div>
                 <div class="text-lg font-medium monitor-txt" v-if="root">{{ Mon.prediction_time }} <span class="text-sm">ms</span> </div> 
                 <div class="text-gray-600 dark:text-gray-600 text-xs" v-if="root">Tiempo de actualización</div>
                 <div class="text-lg font-medium monitor-txt" v-if="root">{{ Mon.update_time }} <span class="text-sm">ms</span></div>   
                 <div class="text-gray-600 dark:text-gray-600 text-xs" :class="root ? '': 'mt-2'">Predicción</div>
                 <div id="pie-chart" class="pl-2 pr-2"><Chart type="pie" :width="ChartSize.width" :height="ChartSize.height" :data="Chart" :options="ChartOpt" /></div>
              </div>
              <div class="col-span-12 xl:col-span-8 xxl:col-span-8 z-10 mh-image" v-if="MonitorImage[Mon.id]">
                <img v-bind:src="'data:image/gif;base64,'+ MonitorImage[Mon.id]" class="w-full" v-if="MonitorImage[Mon.id]">
              </div>
            </div> 
          </div>
        </div>
    </div>
   </div>
</template>

<script>
import { defineComponent, ref, onMounted , onBeforeMount, watch, computed  } from "vue";
import { tag as $tag } from "@/model/tag";
import { helper as $h } from "@/utils/helper";
import { useStore } from "@/store";
import { _firebase as $_firebase } from "@/model/firebase";


export default defineComponent({
  props: {
    monitor: {
      type: String,
      required: true
    }
  },
  setup(props) {
    
    const Mon           = ref({});
    const MonitorImage  = ref({});
    const status        = ref({});
    const controller    = ref({});
    const tags          = ref([]);
    const tagsName      = ref([]);
    const tagColor      = ref();        
    const colors        = ref([]);
    const Chart         = ref();
    const ChartOpt      = ref();
    const ChartSize     = ref( { width: 5, height: 5});
    const StatsChart    = ref( { width: 100, height: 20, data: {}, options: {} });
    const store         = useStore();  
    const User          = computed(() => store.state.main.User);
    const root          = ref(false);

    const refreshChart = (item) => {
        let predLabels = []; let predScores = []; let predColors = []
        
        if(item.prediction && item.prediction.labels){
          let labelsOri   = []
          for (const key of item.prediction.labels) { 
            labelsOri[(key-1)] = {}
            labelsOri[(key-1)].key   = (key-1);
            labelsOri[(key-1)].tagid = item.prediction.labels[(key-1)];
          }
          labelsOri = $h.sortObject(labelsOri,'tagid')
          labelsOri.forEach( i => {
            predLabels.push(tagsName.value[i.tagid])
            predScores.push(item.prediction.scores[i.key])
            predColors.push(colors.value[i.tagid])
          });
        }
        Chart.value    = { labels: predLabels, datasets: [ { backgroundColor: predColors, data: predScores, borderWidth: 1, borderColor: "#fff", },]  };
        ChartOpt.value = { rotation: -Math.PI,legend: { display: false,}, cutoutPercentage: 30, circumference: Math.PI, animation: {animateRotate: false} };
    }

    const getMonitor = (m) => {
      Mon.value  = { 
          id:       m,
          image:    null
      } 
      $_firebase.database().ref(m+'/image_data').on("value", function(snapshot) {
        let item       = snapshot.val();
        MonitorImage.value[m]  = item
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });

      $_firebase.database().ref(m+'/image').on("value", function(snapshot) {
        if(snapshot.val()){
          let item       = snapshot.val();
          refreshChart(item)
          if(Mon.value.id==m){
            Mon.value.date             = $h.getTimestampDate(item.date);
            Mon.value.output           = item.output
            Mon.value.prediction_time  = item.prediction_time
            Mon.value.update_time      = item.update_time
           if(item.prediction)Mon.value.key   = item.prediction.key
            Mon.value.tagname          = tagsName.value[item.tag]
          }
        }
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
      getMonitorStatus(m)
    }

    const getMonitorStatus = (m) => {
     $_firebase.database().ref(m+'/monitor/status').on("value",function(snapshot) {
        status.value[m]    = snapshot.val()
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const getMonitorController = (m) => {
     $_firebase.database().ref(m+'/monitor/controller').on("value",function(snapshot) {
        controller.value[m]    = snapshot.val()
      }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    onBeforeMount( async () => {
      tags.value     = await $tag.get();
      tagsName.value = await $tag.init();
      tagColor.value = await $tag.getColors();
      tagColor.value.forEach(t => { colors.value[t.tag] = t.color; });
    });

    onMounted(  () => { 
      getMonitor(props.monitor); getMonitorController(props.monitor); 
      if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;
    });

    watch(() => props.monitor, () => { getMonitor(props.monitor) });
    
    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    return {
      Mon,
      MonitorImage,
      Chart,
      ChartOpt,
      ChartSize,
      StatsChart,
      controller,
      User,
      root,
      status
    };
  },
});
</script>
<style>
.mh-image{ min-height: 40vh;}
.monitor-output{padding: 1px 0; background-color: #f8f8f8; text-align: center; margin: 5px 0; max-width: 118px;}
.monitor-txt{font-size: 14px !important;}
.monitor-txt2{font-size: 15px !important;}
</style>
