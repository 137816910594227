<template>
  <div>
    <!-- Sidebar -->
    <div class="xxl:border-l border-theme-25 pb-10" v-if="View=='sidebar'">
      <div class="xxl:pl-6 grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-6 xxl:col-span-12 mt-3 xxl:mt-6" >
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">Actividad reciente</h2>
              <a class="ml-auto text-theme-26 dark:text-theme-33 truncate cursor-pointer" v-if="Events.length" @click="reLoad()">Ver más</a>
            </div>
            <div class="report-timeline mt-5 relative">
              <div v-if="Events.length">
                <div class="intro-x relative flex items-center mb-3" v-for="(e) in Events" :key="e.id" >
                  <div class="report-timeline__image" :class="isCritical(e.isCritical)">
                    <div class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden">
                      <ActivityIcon class="w-10 h-10 bg-gray-400 text-white"  />
                    </div>
                  </div>
                  <div class="box px-5 py-3 ml-4 flex-1 zoom-in">
                    <div class="flex items-center">
                      <div class="font-medium">{{ this.eventModel.getEventTypes(e.name) }}</div>
                      <div class="text-xs text-gray-500 ml-auto">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'time') }}</div>
                    </div>
                    <div class="text-gray-600 mt-1">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'date') }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="intro-x text-gray-500 text-xs text-center my-4">No se han registrado nuevos eventos</div>
              </div>
            </div>
          </div>
      </div>
    </div> 
    <!-- Table -->
    <div class="grid grid-cols-12 gap-6" v-if="View=='table'">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3" v-if="!UId">
          <div class="intro-y box mt-3 lg:mt-0">
            <div class="relative flex items-center p-5">
              <div class="w-10 h-10 image-fit"><ActivityIcon class="w-8 h-8"  /></div>
              <div class="ml-2 mr-auto">
                <div class="font-medium text-base">Actividad</div>
                <div class="text-gray-900 text-xs">{{CurType}}</div>
                <div class="text-gray-600 text-xs">
                  <span v-if="Events.length">{{Events.length}} eventos</span>
                  <span v-else>Sin eventos</span>
                </div>
              </div>
            </div>
            <div class="p-5 border-t border-gray-200 dark:border-dark-5 pt-0 mh-menu">
              <div v-for="(type) in EventType" :key="type.id" >
                <a class="flex items-center mt-3 cursor-pointer" :class="TabActive(type.id)" @click="ChangeTab(type.id)"> {{ type.id }}</a>
              </div>
            </div>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9" :class="isUid()">
        <div v-if="Events.length">
          <div class="intro-y box">
            <div class="relative flex items-center p-5" v-if="UId">
              <div class="w-10 h-10 image-fit"><ActivityIcon class="w-10 h-10"  /></div>
              <div class="ml-2 mr-auto">
                <div class="font-medium text-base">Mi actividad</div>
                <div class="text-gray-600 text-xs" v-if="Events.length">{{Events.length}} eventos</div>
              </div>
            </div>
            <table class="table text-xs">
              <thead>
                  <tr>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Fecha</th>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Hora</th>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Evento</th>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Monitor</th>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap" v-if="!UId">Usuario</th>
                      <!-- <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Error</th>
                      <th class="border border-b-2 dark:border-dark-5 whitespace-nowrap">Data</th>-->
                  </tr>
              </thead>
              <tbody>
                  <tr class="hover:bg-gray-200" v-for="(e) in Events" :key="e.id" :class="isCritical(e.isCritical)">
                      <td class="border">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'date') }}</td>
                      <td class="border">{{ this.helper.getTimestampDate(e.createdAt.toDate(),'time') }}</td>
                      <td class="border">{{ this.eventModel.getEventTypes(e.name) }}</td>
                      <td class="border"><span v-if="e.payload.monitor">{{ e.payload.monitor }}</span></td>
                      <td class="border" v-if="!UId">{{ e.payload.uid }}</td>
                      <!-- <td class="border"><span v-if="e.payload.error">{{ e.payload.error }}</span></td>
                       <td class="border">{{ e }}</td>-->
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="text-gray-500 text-xs mt-3" v-if="UId">{{UId}}</div>
        </div>
        <div v-else>
          <div class="h-full flex items-center mt-20">
            <div class="mx-auto text-center">
              <div class="w-16 h-16 flex-none image-fit rounded-full overflow-hidden mx-auto"><ActivityIcon class="w-16 h-16" /></div>
              <div class="mt-3">
                <div class="font-medium">{{ CurType }}</div>
                <div class="text-gray-600 mt-1">No se ha registrado actividad</div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from "vue";
import { helper as $h } from "@/utils/helper";
import { event as $event } from "@/model/events";

export default defineComponent({
  props: {
    uid: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: 'table'
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  created () {
    this.eventModel = $event,
    this.helper     = $h
  },  
  setup(props) {

    const UId       = computed( () => { return props.uid; });
    const View      = computed( () => { return props.view; });
    const Limit     = computed( () => { return props.limit; });
    const Events    = ref([])
    const CurType   = ref('all')
    const EventType = ref()

    const getEvents     = async () => { Events.value = await $event.get({ uid: UId.value, type: CurType.value, limit: Limit.value }) };
    const TabActive     = (t) => {  if(t==CurType.value)return "dark:text-white text-theme-12 font-medium"  };
    const isUid         = () =>  {   if(UId.value)return "lg:col-span-12 xxl:col-span-12"  };
    const isCritical    = (t) => {  if(t)return "bg-theme-24 text-white hover:bg-red-900"  };
    const ChangeTab     = (t) => {  CurType.value = t ; getEvents()};

    onMounted( async () => { 
      EventType.value = await $event.getEventTypes();
      await getEvents()
    });

    return {
     UId,
     View,
     Events,
     EventType,
     isUid,
     CurType,
     TabActive,
     ChangeTab,
     isCritical,
    };
  },
  methods:{
    getLayoutOpt: function(){ return ['sidebar','table'] },
    reLoad: function(){ this.$parent.ProfileMenuRef.ChangeTab('ManageEvents')  }
  }
});
</script>
