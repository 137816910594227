import { _firebase as $_firebase } from "@/model/firebase";
import { settings as $settings } from "@/model/settings";
import { session as $session } from "@/model/session";
import { event as $event } from "@/model/events";
import { helper as $h } from "@/utils/helper";
import { useStore } from "@/store";

//const functions = $_firebase.firebase().app().functions(); //local
const functions = $_firebase.firebase().app().functions('europe-west1'); //online

const monitors = {
    async get(monitorID=false){
        if(monitorID){
            let mon = $_firebase.firestore().collection('monitor').doc(monitorID)
            const snapshot = await mon.get();
            let item = snapshot.data(); item.id = snapshot.id
            return item;
        }else{
            let monlist = []
            let monitors = $_firebase.firestore().collection('monitor')
            await monitors.get().then(snapshot => {
                            snapshot.forEach(async doc => {
                            let item = doc.data()
                            item.id  = doc.id;
                            monlist.push(item)
                            });
                        });
            return monlist
        }
    },
    async getApiUrl(monitorID){
        let Settings  = await $settings.get('api');
        let apipath   = 'monitor/';
        let ApiUrl    = Settings.url_local;
        let training  = await this.isTraining(monitorID);
        if(training)ApiUrl = Settings.url_online;
        //ApiUrl = Settings.url_online;
        return ApiUrl+apipath;
    },
    async getStatus(monitorID){
        await $_firebase.database().ref(monitorID+'/monitor/status').once("value",function(snapshot) { return snapshot.val()
        }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    },
    async getCounter(monitorID){
        let count = 0; let tags = {}; let monController = {}; let monPrediction = {}
        await $_firebase.database().ref(monitorID+'/monitor/tag_counter').once("value", function(snapshot) { 
            let realCounter = snapshot.val()
            if(realCounter){
                Object.keys(realCounter).map(async function(tagID){
                    let score = realCounter[tagID];
                    tags[tagID] = score
                    count += score;
                });
            }
        }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });

        await $_firebase.database().ref(monitorID+'/monitor/controller').once("value", function(snapshot) { 
            monController = snapshot.val()
        }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });

        await $_firebase.database().ref(monitorID+'/monitor/prediction').once("value", function(snapshot) { 
            monPrediction = snapshot.val()
        }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });

        return { total: count, tags: tags, controller: monController, prediction: monPrediction }
    },
    async isTraining(monitorID){
        let training = false;
        await $_firebase.database().ref(monitorID+'/monitor/training').once("value",function(snapshot) { training = snapshot.val()  }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
        return training;
    },
    async isPlay(monitorID){
        let run = false;
        await $_firebase.database().ref(monitorID+'/monitor/status').once("value",function(snapshot) { run = snapshot.val()  }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
        return run;
    },
    async play(monitorID,msg=false){
       let run          = await this.isPlay(monitorID)
       let SessionID    = false
       if(!run){ 
        const action = functions.httpsCallable('api/monitor/'+monitorID+'/start');
        await action({}).then( async () => {
                        if(msg)$h.Notification(msg);
                        await $event.saveEvent('monitor.start', { uid: useStore().state.main.User.uid, monitor: monitorID }, false)
                        SessionID = await $session.start({ monitor: monitorID })
                    }).catch(async (error) => { 
                        await $event.saveEvent('monitor.start', { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true)    
                    });
       }
       return SessionID;
    },
    async stop(monitorID,msg=false){
       let run = await this.isPlay(monitorID)
       if(run){ 
        let CurSession = await $session.getLast({ uid : useStore().state.main.User.uid , monitor: monitorID })
        const action = functions.httpsCallable('api/monitor/'+monitorID+'/stop');
        await action({}).then( async () => {
                            let counter = await this.getCounter(monitorID);
                            if(CurSession.data && CurSession.data.training)counter.training = CurSession.data.training;
                            if(CurSession.id)await $session.end(CurSession.id, counter)
                            if(msg)$h.Notification(msg);
                            let training = await this.isTraining(monitorID)
                            if(training)await this.trainingStop(monitorID,false)
                            await $event.saveEvent('monitor.stop', { uid: useStore().state.main.User.uid, monitor: monitorID }, false)
                        }).catch( async (error) => { 
                            await $event.saveEvent('monitor.stop', { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true)    
                        });
      }
    },
    async isDemo(monitorID){
        let run = false;
        await $_firebase.database().ref(monitorID+'/monitor/demo/status').once("value",function(snapshot) { run = snapshot.val()  }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
        return run;
    },
    async demo(monitorID){
        let run            = await this.isDemo(monitorID)
        let demoEvent      = ''
        let demoAction     = ''
        let demoActionTxt  = ''
        if(!run){ 
            demoEvent      = 'monitor.demo.start'
            demoAction     = 'start'
            demoActionTxt  = 'Modo demo activo'
        }else{
            demoEvent = 'monitor.demo.stop'
            demoAction = 'stop'
            demoActionTxt  = 'Modo demo inactivo'
        }
        const action  = functions.httpsCallable('api/monitor/'+monitorID+'/demo/'+demoAction);
        await action({}).then( async () => {
            $h.NotificationTxt({ text: demoActionTxt});
            await $event.saveEvent(demoEvent, { uid: useStore().state.main.User.uid, monitor: monitorID }, false)
        }).catch(async (error) => { await $event.saveEvent(demoEvent, { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true)    });
     },
     async updateDemo(DemoSet){
        await $_firebase.firestore().collection("monitor").doc(DemoSet.monitor.toString())
                        .update(DemoSet.data);
     },
    async OutputChange(monitorID,OutputAction){
        let outputEvent      = ''
        let outputActionTxt  = ''
        const action  = functions.httpsCallable('api/monitor/'+monitorID+'/controller/output/'+OutputAction);
        if(OutputAction=='start'){ outputEvent = 'monitor.controller.output.start'; outputActionTxt  = 'Salida activada'  
        }else{ outputEvent = 'monitor.controller.output.stop'; outputActionTxt  = 'Salida desactivada' }
        await action({}).then( async () => {
            $h.NotificationTxt({ text: outputActionTxt});
            await $event.saveEvent(outputEvent, { uid: useStore().state.main.User.uid, monitor: monitorID }, false)
        }).catch(async (error) => { await $event.saveEvent(outputEvent, { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true)    });
    },
    async SendOutput(monitorID,Output){
        const action  = functions.httpsCallable('api/monitor/'+monitorID+'/controller/command/send_output/value/'+Output);
        $h.NotificationTxt({ text: 'Salida '+Output+' definida para monitor '+monitorID });
        await action({}).then( async () => {
            await $event.saveEvent('monitor.controller.command.sendoutput', { uid: useStore().state.main.User.uid, monitor: monitorID }, false)
        }).catch(async (error) => { await $event.saveEvent('monitor.controller.command.sendoutput', { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true)    });
    },
    async trainingToggle(monitorID,msg=false){
        let run = await this.isPlay(monitorID)
        if(run){ 
            let training   = await this.isTraining(monitorID)
            if(training){ if(msg)msg = '#stop-notification-training'; await this.trainingStop(monitorID,msg); await this.trainingSession(monitorID, 'stop')
            }else{ 
                if(msg)msg = '#start-notification-training'; 
                await this.trainingStart(monitorID,msg)
                await this.trainingSession(monitorID, 'start')
            }
        }
     },
     async trainingStart(monitorID,msg=false){
        let run = await this.isPlay(monitorID)
        if(run){ 
            let training = await this.isTraining(monitorID)
            if(!training){
                const action = functions.httpsCallable('api/monitor/'+monitorID+'/training/start');
                action({}).then( async () => {
                                if(msg)$h.Notification(msg);
                                await $event.saveEvent('monitor.training.start', { uid: useStore().state.main.User.uid, monitor: monitorID }, false); 
                            }).catch( async (error) => { 
                                await $event.saveEvent('monitor.training.start', { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true) 
                            });
            }
       }
     },
     async trainingStop(monitorID,msg=false){
        let training = await this.isTraining(monitorID)
        if(training){
            const action = functions.httpsCallable('api/monitor/'+monitorID+'/training/stop');
            action({}).then( async () => {
                            if(msg)$h.Notification(msg);
                            await $event.saveEvent('monitor.training.stop', { uid: useStore().state.main.User.uid, monitor: monitorID }, false); 
                        }).catch( async (error) => { 
                            await $event.saveEvent('monitor.training.stop', { uid: useStore().state.main.User.uid, monitor: monitorID, error: error.message }, true) 
                        });
        }
     },
     async trainingSession(monitorID, action){
        let CurSession = await $session.getLast({ uid : useStore().state.main.User.uid , monitor: monitorID })
        let sessionTraining = []; if(CurSession.data && CurSession.data.training)sessionTraining = CurSession.data.training;
        sessionTraining.push({ action: action, time: $_firebase.firebase().firestore.Timestamp.now()})
        await $session.updateData(CurSession.id,{ data: { training : sessionTraining  } })
     },
     async getRules(monitorID){
         let rules = {}
         let mon = $_firebase.firestore().collection('monitor').doc(monitorID.toString()).collection('rules')
         await mon.get().then(snapshot => {
             snapshot.forEach(async doc => {
             let item = doc.data()
             item.id  = doc.id;
             rules[doc.id] = item;
             });
         });
         return rules;
     },
     async updateRule(Rule){
        Rule.data["updatedAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("monitor").doc(Rule.monitor.toString()).collection('rules').doc(Rule.id)
                        .update(Rule.data);
     },
     async addRule(Rule){
        let NewRuleID = false
        Rule.data["createdAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("monitor").doc(Rule.monitor.toString()).collection('rules').add(Rule.data)
                        .then(function(docRef) { NewRuleID = docRef.id; })
                        .catch(function(error) { console.error("Error adding rule: ", error);  });
        return NewRuleID
     },
     async delRule(MonitorID,RuleID){
        if(RuleID){ await $_firebase.firestore().collection("monitor").doc(MonitorID.toString()).collection('rules').doc(RuleID).delete(); }
     },
};

const install = app => {
  app.config.globalProperties.$monitor = monitors;
};

export { install as default, monitors as monitor };
