import { _firebase as $_firebase } from "@/model/firebase";

const settings = {
    async get(doc=false){
        if(doc){
            let mon = $_firebase.firestore().collection('settings').doc(doc)
            const snapshot = await mon.get(); let item = snapshot.data(); item.id = snapshot.id
            return item;
        }else{
            let settings = {}
            let set      = $_firebase.firestore().collection('settings')
            await set.get().then(snapshot => { snapshot.forEach(async doc => { settings[doc.id] = doc.data(); }); });
            return settings
        }
    },
};

const install = app => {
  app.config.globalProperties.$settings = settings;
};

export { install as default, settings as settings };
