<template>
  <div>
    <div class="grid chat grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-5 xxl:col-span-3">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="chat__tabs nav nav-tabs justify-center" role="tablist">
              <a
                id="dataset-tab"
                data-toggle="tab"
                data-target="#dataset"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center" :class="curDataset ? 'active' : ''" 
                role="tab"
                aria-controls="dataset"
                aria-selected="true"
                v-on:click="curModel=false; curDataset=datasets[0].id"
                ><ListIcon class="w-4 h-4 mr-1" />Conjuntos de datos
                </a
              >
              <a
                id="model-tab"
                data-toggle="tab"
                data-target="#model"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center" :class="curModel ? 'active' : ''" 
                role="tab"
                aria-controls="model"
                aria-selected="false"
                v-on:click="curDataset=false; curModel=models[0] ? models[0].modelID : ''"
                ><CpuIcon class="w-4 h-4 mr-1" />Modelos</a
              >
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div id="dataset" class="tab-pane" :class="curDataset ? 'active' : ''"  role="tabpanel" aria-labelledby="dataset-tab">
            <div class="right-0 top-0 mr-5 mt-3" :class="createDataset ? 'hidden' : ''">
              <button type="button" class="btn btn-outline-secondary w-fill" @click="newDataset(true)"><PlusIcon class="w-4 h-4 mr-2" />Nuevo conjunto de datos</button>
            </div>
            <!-- Create Dataset --> 
            <div class="pr-1" :class="!createDataset ? 'hidden' : ''">
              <div class="box p-5 mt-5 mb-3">
                <form ref="newDataset" class="validate-form" @submit.prevent="addDataset('newDataset',$event)">
                  <div class="relative text-gray-700 dark:text-gray-300">
                    <input type="text" name="dsname" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 " placeholder="Nombre" required />
                    <SearchIcon class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0" />
                  </div>
                  <select name="dstype" class="form-select mt-3 sm:mr-2 cursor-pointer bg-gray-200 w-full">
                      <option value="MULTILABEL">Multilabel</option>
                      <option value="MULTICLASS">Multiclass</option>
                  </select>
                  <button type="submit" class="btn btn-primary w-1/2 mt-3">Crear</button>
                  <button type="button" class="btn btn-secondary w-1/2 mt-3" @click="closeAddDataset()">Cancelar</button>
                 </form>
              </div>
            </div>
            <div class="scrollbar-hidden pr-1 pt-1" :class="datasets ? '' : 'hidden'">
              <div class="cursor-pointer box relative flex items-center p-5 mt-2" v-for="(dataset) in datasets" :key="dataset.id" :class="dataset.id==curDataset ? 'bg-gray-100' : ''">
                <div class="w-12 h-12 flex-none image-fit mr-1" @click="ChangeDataset(dataset.id)">
                  <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 text-center pt-1.5 hover:bg-theme-17" 
                       :class="dataset.id==curDataset ? 'bg-theme-17' : 'bg-theme-21'"
                       role="button" aria-expanded="false">
                    <span class="text-white text-2xl">{{  dataset.displayName.charAt(0) }}</span>
                  </div>
                  <div class="w-3 h-3 bg-theme-10 absolute right-0 bottom-0 rounded-full border-2 border-white z-10"></div>
                </div>
                <div class="ml-2 overflow-hidden" @click="ChangeDataset(dataset.id)">
                  <div class="flex items-center">
                    <a class="font-medium truncate"  :class="dataset.id==curDataset ? 'text-theme-17' : ''">{{ dataset.displayName }}</a>
                  </div>
                  <div class="w-full truncate text-gray-600 mt-0.5">{{ dataset.id }}</div>
                </div>
                <div class="dropdown ml-auto">
                  <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer" @click="ChangeDataset(dataset.id)">
                        <EditIcon class="w-4 h-4 mr-2" />Editar
                      </a>
                      <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                        <TrashIcon class="w-4 h-4 mr-2" />Eliminar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="model" class="tab-pane" :class="curModel ? 'active' : ''" role="tabpanel" aria-labelledby="model-tab" >
            <div class="right-0 top-0 mr-5 mt-3" :class="createModel ? 'hidden' : ''">
              <button type="button" class="btn btn-outline-secondary w-fill"><PlusIcon class="w-4 h-4 mr-2" />Nuevo modelo</button>
            </div>
            <!-- Create Model --> 
            <div class="pr-1" :class="!createModel ? 'hidden' : ''">
              <div class="box p-5 mt-5 mb-3">
                <form ref="newModel" class="validate-form">
                  <div class="relative text-gray-700 dark:text-gray-300">
                    <input type="text" name="modelname" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 " placeholder="Nombre" required />
                    <SearchIcon class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0" />
                  </div>
                  <button type="submit" class="btn btn-primary w-1/2 mt-3">Crear</button>
                  <button type="button" class="btn btn-secondary w-1/2 mt-3" v-on:click="createModel=false">Cancelar</button>
                 </form>
              </div>
            </div>
            <div class="pr-1">
              <div class="box p-5 mt-5 pt-0">
                <div v-if="models.length">
                  <div class="cursor-pointer flex items-center border-b border-gray-200 dark:border-dark-5 pb-5 pt-5" v-for="(model) in models" :key="model.modelID">
                    <div @click="ChangeModel(model.modelID)">
                      <div class="text-gray-600">{{ model.displayName }}</div>
                      <div class="mt-1">{{ model.modelID }}</div>
                    </div>
                    <CpuIcon class="w-8 h-8 ml-auto hover:text-theme-12" :class="model.modelID==curModel ? 'text-theme-12' : 'text-gray-600'" @click="ChangeModel(model.modelID)" />
                  </div>
                </div>
                <div v-else>
                  <div class="pt-5"><div class="col-span-12 items-center w-full h-12"><LoadingIcon icon="grid" class="w-12 h-12" /> </div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-7 xxl:col-span-9">
        <!-- datasets -->
        <div v-for="(dataset) in datasets" :key="dataset.id">
            <div class="intro-y box w-full" v-if="dataset.id == curDataset">
               <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class=" text-base mr-auto"><ListIcon class="w-4 h-4 mr-1" /> Conjunto de datos <a href="javascript:;" data-toggle="modal" data-target="#dataset-info-box" class="mr-1 mb-2"><InfoIcon class="w-4 h-4 mr-1" /></a></h2>
                <div class="ml-auto" :class="ImportForm ? 'hidden' : ''"><button type="button" class="btn btn-primary py-2 px-4" @click="importCSV(dataset.id)"><FolderPlusIcon class="w-4 h-4 mr-1" /> Importar</button></div>
              </div>
              <div class="preview p-5">
                <div v-if="ImportForm" class="mb-5">
                  <form ref="importCsv" class="validate-form" @submit.prevent="importDataset('importCsv',$event)">
                    <div class="text-base font-bold">Selecciona archivos para importar</div>
                    <div class="text-xs pt-2">
                        <span class="font-bold">No puedes importar datos a un conjunto de datos que ya los contenga.</span><br>
                        A fin de crear un modelo personalizado, primero debes importar un conjunto de imágenes para entrenarlo.<br>
                        Cada imagen debe estar categorizada con una etiqueta. Las etiquetas son esenciales para indicar al modelo cómo identificar una imagen.<br><br>
                        <span class="font-medium">Cada etiqueta debe tener al menos 100 imágenes para lograr mejores resultados.</span>
                    </div>
                    <div class="mt-4">
                        <div class="form-check mt-2">
                            <input class="form-check-input" type="radio" name="csv" value="csv" checked disabled/>
                            <label class="form-check-label" for="radio-switch-1">
                              Seleccionar un archivo CSV en Cloud Storage
                            </label>
                        </div>
                        <input type="text" name="modelname" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 mt-2" placeholder="gs://" required />
                        <button type="submit" class="btn btn-primary w-32 mt-3">Continuar</button>
                        <div class="text-xs mt-5">
                          Sube los archivos a <a href="https://console.cloud.google.com/storage/browser" target="_blank" class="text-blue-500">Cloud Storage</a>.<br>
                          El archivo CSV debe ser una lista de rutas de acceso de GCS a tus imágenes.<br>
                          Las imágenes pueden tener los formatos JPG, PNG, GIF, BMP o ICO.<br>
                          De manera opcional, puedes especificar la división TRAIN, VALIDATION o TEST.
                        </div>
                    </div>
                  </form>
                </div>
                <div class="text-base font-bold mb-3">Detalles del conjunto</div>
                 <table class="table table--sm">
                    <tbody>
                        <tr class="hover:bg-gray-200"><td class="border">Nombre</td><td class="border font-bold">{{dataset.displayName}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">ID del conjunto</td><td class="border">{{dataset.id}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">Cantidad total de imágenes </td>
                        <td class="border" :class="dataset.exampleCount ? '' : 'bg-red-200'">{{ this.helper.formatScore(dataset.exampleCount) }}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">Fecha de creación</td><td class="border">{{dataset.createTime.seconds}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">ETag</td><td class="border">{{dataset.etag}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">Metadatos del conjunto</td><td class="border font-medium">{{dataset.datasetMetadata}}</td></tr>
                        <tr class="hover:bg-gray-200" v-if="dataset.imageClassificationDatasetMetadata"><td class="border">Tipo de clasificación</td><td class="border">{{dataset.imageClassificationDatasetMetadata.classificationType}}</td></tr>
                        <tr class="hover:bg-gray-200" v-if="dataset.imageObjectDetectionDatasetMetadata"><td class="border">imageObjectDetectionDatasetMetadata</td><td class="border">{{dataset.imageObjectDetectionDatasetMetadata}}</td></tr>
                    </tbody>
                 </table>
                 <div id="dataset-info-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-header p-5"><h2 class="font-medium text-base mr-auto">Conjunto de datos</h2></div>
                            <div class="modal-body">
                              <span class="font-bold">Cuando actualizas un conjunto de datos o su información de esquema</span>, influyes en cualquier modelo futuro que use ese conjunto de datos. Los modelos que ya se comenzaron a entrenar no se verán afectados.
                            </div>
                        </div>
                    </div>
                 </div>
              </div>
            </div>
        </div> 
        <!-- Models -->
        <div :class="curModel ? '' : 'hidden'">
          <div v-for="(model) in models" :key="model.modelID">
              <div class="intro-y box " v-if="model.modelID == curModel">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto"><CpuIcon class="w-4 h-4 mr-1" /> {{ model.displayName }}</h2>
                    <div class="ml-auto" :class="ModelEvaluation ? 'hidden' : ''"><button type="button" class="btn btn-primary py-2 px-4" @click="getEvaluation(model.modelID)"><CodepenIcon class="w-4 h-4 mr-1" /> Evaluar</button></div>
                  </div>
                  <div class="p-5">
                    <div class="preview pb-5">
                      <!-- Model Evaluation -->
                      <div :class="ModelEvaluation ? '' : 'hidden'" class="mb-5">
                          <div class="text-base pb-3 font-bold">Evaluación del modelo</div>
                          <div div class="inline-block align-top pr-20 w-64"> 
                            <div class="text-base">Límite de confianza</div>
                              <select class="form-select mt-2 sm:mr-2 cursor-pointer bg-gray-600 font-bold text-white w-40" v-if="ModelEvaluation[0] && ModelEvaluation[0].metrics.precisionThreshold" @change="setConfidenceLimit($event)">
                                  <option v-for="(t,k) in ModelEvaluation[0].metrics.precisionThreshold" v-bind:key="k" :selected="k==confidenceLimit">{{k}}</option>
                              </select>
                              <div class="text-xs pt-2">Modifique el límite para ver qué umbral de confianza funciona mejor para su modelo.</div>
                              <br>
                              <a href="javascript:;" data-toggle="modal" data-target="#model-info-box" class="mr-1 mb-2"><InfoIcon class="w-4 h-4 mr-1" />Más información</a>
                          </div>
                          <div div class="inline-block align-top"> 
                            <table class="table table--sm mb-5">
                              <tbody>
                                  <tr class="hover:bg-gray-200">
                                    <td></td>
                                    <td class="border">Rendimiento</td>
                                    <td class="border">Precisión</td>
                                    <td class="border">Recuperación</td>
                                    <td class="border">Total imágenes</td>
                                    <td class="border">Elementos<br>de prueba</td>
                                  </tr>
                                  <tr class="hover:bg-gray-200" v-for="me in ModelEvaluation" v-bind:key="me.id">
                                    <td class="border"><span v-if="me.displayName">{{ me.displayName }}</span><span v-else>Todas las etiquetas</span></td>
                                    <td class="border text-center" v-if="me.metrics && me.metrics.auPrc" :class="me.metrics.auPrc==1 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{ me.metrics.auPrc }}
                                    </td>
                                    <td class="border text-center" v-if="me.metrics.precisionThreshold[confidenceLimit]" :class="me.metrics.precisionThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{me.metrics.precisionThreshold[confidenceLimit]}}%
                                    </td><td v-else class="border">-</td>
                                    <td class="border text-center" v-if="me.metrics.recallThreshold[confidenceLimit]" :class="me.metrics.recallThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{me.metrics.recallThreshold[confidenceLimit]}}%
                                    </td><td v-else class="border">-</td>
                                    <td class="border text-center">
                                      <span v-if="model.dataset">
                                        <span v-if="model.dataset.exampleCount && me.evaluatedExampleCount">
                                          {{ this.helper.formatScore(model.dataset.exampleCount-me.evaluatedExampleCount) }}
                                        </span>
                                      </span>
                                    </td>
                                    <td class="border text-center">{{ this.helper.formatScore(me.evaluatedExampleCount) }}</td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                          <div class="mt-2" v-if="DataMatrix">
                            <div class="text-base pb-2 font-bold">Matriz de confusión</div>
                            <div class="text-xs">En esta tabla, se muestra la frecuencia con la que el modelo clasificó cada etiqueta de manera correcta (en <span class="text-blue-500">color azul</span>) y qué etiquetas se confundieron más frecuentemente con esa etiqueta (en <span class="text-gray-500">color gris</span>).</div>
                            <table class="table table--sm mb-5 w-full lg:w-2/3 mt-5 mb-5">
                              <tbody>
                                  <tr class="hover:bg-gray-200">
                                    <td class="w-40"></td>
                                    <td :colspan="DataMatrix.displayName.length"><span class="text-gray-500">Etiqueta predicha</span></td>
                                  </tr>
                                  <tr class="hover:bg-gray-200">
                                    <td class="w-40"><span class="text-gray-500">Etiqueta de confianza</span></td>
                                    <td class="text-center font-bold datamatrixColHeader" v-for="name in DataMatrix.displayName" v-bind:key="name">{{name}}</td>
                                  </tr>
                                  <tr class="hover:bg-gray-200" v-for="(name,idx1) in DataMatrix.displayName" v-bind:key="name">
                                    <td class="border text-center w-40">{{name}}</td>
                                    <td v-for="(val,idx2) in DataMatrix.row[idx1].exampleCount" v-bind:key="val" class="border text-center" 
                                      :class="!val ? '' : idx2==idx1 ? 'bg-blue-500 text-white' : 'bg-gray-500'">
                                      <span v-if="val==0">-</span><span v-else>
                                         {{ this.helper.formatScore(val) }}
                                      </span>
                                      <br>
                                      <span class="text-xs" v-if="val">{{ Math.round(((val / DataMatrix.rowsCount[idx1]) * 100).toFixed(2)) }}%</span>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                          </div> 
                          <div id="model-info-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
                              <div class="modal-dialog modal-sm">
                                  <div class="modal-content">
                                      <div class="modal-header p-5">
                                          <h2 class="font-medium text-base mr-auto">Evaluación del modelo</h2>
                                      </div>
                                      <div class="modal-body">
                                          <div class="text-xs"><span class="font-bold">Umbral de puntuación</span><br />El umbral de puntuación se refiere al nivel de confianza que debe tener el modelo para asignar una categoría a un elemento de prueba.<br><br> Si el umbral de puntuación es bajo, tu modelo clasificará más imágenes, pero corres el riesgo de clasificar de forma errónea algunas imágenes en el proceso.<br><br> Si el umbral de puntuación es alto, tu modelo clasificará menos imágenes, pero tendrá un riesgo menor de clasificar de manera incorrecta las imágenes.</div>
                                          <div class="text-xs mt-5"><span class="font-bold">Precisión</span><br />Un modelo de alta precisión produce menos falsos positivos.</div>
                                          <div class="text-xs mt-5"><span class="font-bold">Recuperación</span><br />Un modelo con alto grado de recuperación produce menos falsos negativos.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="accordion mb-5">
                        <!-- Model Details -->
                        <div class="accordion-item">
                            <div id="accordion-content-1" class="accordion-header show">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-1" aria-expanded="true" aria-controls="accordion-collapse-1">
                                 <ChevronsRightIcon class="w-5 h-5 align-top" /> Detalles del modelo
                                </button>
                            </div>
                            <div id="accordion-collapse-1" class="accordion-collapse collapse show" aria-labelledby="accordion-content-1" data-bs-parent="#accordion-1">
                                <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                    <table class="table table--sm mt-5">
                                      <tbody>
                                          <tr class="hover:bg-gray-200" v-if="model.modelID"><td class="border">ID de modelo</td><td class="border font-medium">{{ model.modelID }}</td></tr>
                                          <tr class="hover:bg-gray-200" v-if="model.dataset && model.dataset.displayName">
                                            <td class="border">Conjunto de datos</td>
                                            <td class="border font-medium">
                                              <div class="cursor-pointer" v-on:click="curModel=false; curDataset=model.dataset.id">{{ model.dataset.displayName }}</div>
                                            </td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.name">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="Formato: proyectos / {project_id} / locations / {locationId} / models / {modelId}">
                                                  Nombre de recurso del modelo
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.name }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.displayName">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="El nombre del modelo que se mostrará en la interfaz. El nombre puede tener hasta 32 caracteres y solo puede constar de letras latinas ASCII A-Z y a-z, guiones bajos (_) y dígitos ASCII 0-9. Debe comenzar con una letra.">
                                                  Nombre interno
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.displayName }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.createTime">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="Marca de tiempo cuando finalizó el entrenamiento del modelo y se puede usar para la predicción.">
                                                  Fecha de creación
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ this.helper.getUnixDate(model.createTime.seconds) }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.updateTime">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="Marca de tiempo de la última actualización de este modelo.">
                                                  Fecha de actualización
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.updateTime.seconds }} {{ this.helper.getUnixDate(model.updateTime.seconds) }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.deploymentState">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="Sólo puede atender solicitudes de predicción después de su implementación.">
                                                  Estado de implementación del modelo
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.deploymentState }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.modelMetadata">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  content="Debe coincidir con el tipo de metadatos del conjunto de datos utilizado para entrenar el modelo.">
                                                  Metadatos del modelo
                                              </Tippy>
                                            </td>
                                            <td class="border font-normal">
                                              <div v-if="model.modelMetadata=='imageClassificationModelMetadata'" class="font-medium">Metadata for image classification models.</div>
                                              {{ model.modelMetadata }}
                                            </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                </div>
                            </div>
                        </div>
                        <!-- Model Metadata -->
                        <div class="accordion-item" v-if="model.imageClassificationModelMetadata">
                            <div id="accordion-content-2" class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-2" aria-expanded="false" aria-controls="accordion-collapse-2">
                                   <ChevronsRightIcon class="w-5 h-5 align-top" /> Metadatos para la clasificación de imágenes
                                </button>
                            </div>
                            <div id="accordion-collapse-2" class="accordion-collapse collapse" aria-labelledby="accordion-content-2" data-bs-parent="#accordion-1">
                                <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                   <table class="table table--sm mt-5">
                                    <tbody>
                                        <tr class="hover:bg-gray-200">
                                          <td class="border w-40"><Tippy tag="a" href="javascript:;" content="baseModelId">ID del modelo base</Tippy></td>
                                          <td class="border font-medium"><span v-if="model.imageClassificationModelMetadata.baseModelId">{{ model.imageClassificationModelMetadata.baseModelId }}</span><span v-else>Ninguno</span></td>
                                          <td class="border">
                                          <span class="text-xs">Si se especifica, el nuevo modelo se creará basándose en el modelo base.<br>De lo contrario, el nuevo modelo se creará desde cero.<br>El modelo base debe estar en el mismo proyecto y ubicación que el nuevo modelo para crear, y tener el mismo tipo de modelo.</span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.stopReason">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="stopReason">Motivo de la detención</Tippy></td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.stopReason }}</td>
                                          <td class="border">
                                          <span class="text-xs">La razón por la que esta operación de creación de modelo se detuvo.</span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.modelType">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="modelType">Tipo de modelo</Tippy></td>
                                          <td class="border font-medium">
                                            <span v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                              <Tippy tag="a" href="javascript:;" :content="model.imageClassificationModelMetadata.modelType">Mayor exactitud en dispositivo móvil</Tippy>
                                            </span>
                                            <span v-else>{{ model.imageClassificationModelMetadata.modelType }}</span>
                                          </td>
                                          <td class="border">
                                          <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                            Se trata de un modelo que, además de proporcionar predicciones a través de la API de AutoML, también se puede exportar (consulte AutoMl.ExportModel) y luego se puede usar en un dispositivo móvil o perimetral con TensorFlow. Se espera que tenga una latencia más alta, pero también debería tener una calidad de predicción más alta que otros modelos
                                          </span>
                                          <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='cloud'">
                                            Modelo que se utilizará mediante llamadas de predicción a la API de AutoML. Este es el valor predeterminado. 
                                          </span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeQps">
                                          <td class="border">NodeQps</td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeQps }}</td>
                                          <td class="border"><span class="text-xs">Número aproximado de QPS de predicción en línea que puede admitir este modelo por cada nodo en el que se implementa.</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeCount">
                                          <td class="border">nodeCount</td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeCount }}</td>
                                          <td class="border"><span class="text-xs">La cantidad de nodos en los que se implementa este modelo.<br /> Un nodo es una abstracción de un recurso de máquina, que puede manejar la predicción en línea QPS como se indica en el campo nodeQps.</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainBudgetMilliNodeHours">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="trainBudgetMilliNodeHours">Presupuesto para<br /> la creación de este modelo</Tippy></td>
                                          <td class="border font-normal">
                                            <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainBudgetMilliNodeHours/1000).toFixed(2) }} Horas de nodo</div>
                                            <span class="text-xs">Valor: {{ model.imageClassificationModelMetadata.trainBudgetMilliNodeHours }}</span>
                                          </td>
                                          <td class="border"><span class="text-xs">El presupuesto de tren para la creación de este modelo, expresado en mili horas de nodo, es decir, el valor 1000 en este campo significa 1 hora de nodo. El costo de tren real será igual o menor que este valor.<br />Si el entrenamiento adicional del modelo deja de proporcionar mejoras, se detendrá sin usar el presupuesto completo y el motivo de la detención será MODEL_CONVERGED..</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainCostMilliNodeHours">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="trainCostMilliNodeHours">Coste real</Tippy></td>
                                          <td class="border font-normal">
                                            <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainCostMilliNodeHours/1000).toFixed(2) }} Horas de<br />procesamiento<br /> de nodo</div>
                                            <span class="text-xs">Valor: {{ model.imageClassificationModelMetadata.trainCostMilliNodeHours }}</span>
                                          </td>
                                          <td class="border"><span class="text-xs">El costo de tren real de crear este modelo, expresado en mili horas de nodo, es decir, el valor de 1,000 en este campo significa 1 hora de nodo. Garantizado para no exceder el presupuesto del tren.</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { model as $model } from "@/model/model";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  created () {
    this.helper = $h
  },  
  setup() {

    const models     = ref([])
    const datasets   = ref(false)
    const curModel   = ref()
    const curDataset = ref()
    const DataMatrix = ref(false)
    const createDataset   = ref(false);
    const createModel     = ref(false);
    const confidenceLimit = ref(0.5)
    const ModelEvaluation = ref(false)
    const ImportForm      = ref(false)

    const ChangeModel = (m) => {  curModel.value = m; confidenceLimit.value = 0.5; ModelEvaluation.value = false;  ModelEvaluation.value = false; DataMatrix.value = false;};
    const ChangeDataset = (d) => { curDataset.value = d; ImportForm.value = false; }
    const getEvaluation = async (modelID) => { 
      ModelEvaluation.value = await $model.getEvaluation(modelID); 
      if(ModelEvaluation.value)DataMatrix.value = await $model.getEvaluationDatamatrix(ModelEvaluation.value)
    };
    const setConfidenceLimit = (e) => { confidenceLimit.value = e.target.value }
    const importCSV = (dsID) => { 
      console.log(dsID); 
      ImportForm.value = true;
    }
    
    onMounted( async () => { 
      datasets.value = await $model.getDataset();  if(datasets.value[0])curDataset.value = datasets.value[0].id 
      models.value   = await $model.get(); 
    });

    return {
      models,
      datasets,
      curModel,
      curDataset,
      createDataset,
      createModel,
      confidenceLimit,
      DataMatrix,
      ImportForm,
      importCSV,
      ChangeModel,
      ChangeDataset,
      ModelEvaluation,
      getEvaluation,
      setConfidenceLimit
    };
  },
  methods:{
    newDataset: function(){ this.createDataset = true; },
    closeAddDataset: function(){ this.createDataset = false; },
    addDataset: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let DisplayName = "";
        let Type = ""
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="dsname")DisplayName = this.$refs[formID][i].value.toLowerCase();
          if(this.$refs[formID][i].name=="dstype")Type = this.$refs[formID][i].value
        }  
        if(DisplayName){
           await $model.addDataset(DisplayName,Type).then(async response =>  {
            if(response.name){
              console.log('name:'+response.name)
              let newDatasetID = response.name.split('/')[5]; 
              $h.NotificationTxt({ text: 'Se ha creado el conjunto de datos: '+newDatasetID});
              this.datasets    = await $model.getDataset();  
              if(newDatasetID)this.curDataset = newDatasetID 
            }
          })
        }
      }
    },
  }
});
</script>
<style>
.datamatrixColHeader{transform: translateX(0) translateY(-5%) rotate(-45deg) !important; width:70px;}
</style>
