import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import { _firebase as $_firebase } from "@/model/firebase";

// SASS Theme
import "./assets/sass/app.scss";

$_firebase.setHost();

const app = createApp(App)
  .use(store)
  .use(router);

app.config.isCustomElement = tag => tag.startsWith('smart-');

globalComponents(app);
utils(app);


app.mount("#app");