import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import Training from "../views/training/Main.vue";
import Profile  from "../views/profile/Main.vue";
import Sessions from "../views/sessions/Main.vue";
import Monitor  from "../views/monitor/Main.vue";
import SeedLog  from "../views/log/Main.vue";
import Settings from "../views/settings/Main.vue";
import Analyze  from "../views/analyze/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import Documentation from "../views/doc/Main.vue";
import Login from "../views/login/Main.vue";
const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "training",
        name: "side-menu-training",
        component: Training
      },
      {
        path: "settings",
        name: "side-menu-settings",
        component: Settings
      },
      {
        path: "/",
        name: "side-menu-monitor",
        component: Monitor
      },
      {
        path: "log",
        name: "side-menu-log",
        component: SeedLog
      },
      {
        path: "analyze",
        name: "side-menu-analyze",
        component: Analyze
      },
      {
        path: "profile",
        name: "side-menu-profile",
        component: Profile
      },   
      {
        path: "sessions",
        name: "side-menu-sessions",
        component: Sessions
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage
  },
  {
    path: "/doc",
    name: "doc",
    component: Documentation
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

export default router;
