<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-5">
        <div class="intro-y box">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Seleccione la imagen que desea analizar</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
            </div>
          </div>
          <div id="single-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneSingleRef" :options="dropzoneOptions"
                class="dropzone"
              >
                <div class="text-lg font-medium">
                  Suelta la image aquí o haz clic para subirla. 
                </div>
                <div class="text-gray-600">
                  Se realizará un  
                  <span class=“font-medium”>análisis</span> para obtener su clasificación.
                </div>
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-7">
        <div class="intro-y box p-5" v-if="resultbox">
          <div>
            <div class="intro-y box bg-theme-1 p-5">
              <div align="center">
                <h3 class="text-2xl font-medium leading-none text-white">{{ uploadtag }}</h3>
              </div>
            </div>
            <div class="preview mt-10">
                <div align="center">
                  <img :src="getuploadImage()" style="max-width: 90%">
                </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
     <!-- BEGIN: Notification Content -->
      <div
        id="success-notification-content"
        class="toastify-content hidden flex"
      >
        <CheckCircleIcon class="text-theme-10" />
        <div class="ml-4 mr-4">
          <div class="text-gray-600 mt-1">
            Espere por favor, La imagen está siendo procesada...
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
  </div>
</template>

<script>
import { defineComponent, ref, provide, onMounted  } from "vue";
import Toastify from "toastify-js";
import cash from "cash-dom";
/*
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
require('firebase/auth');*/
import { _firebase as $_firebase } from "@/model/firebase";


export default defineComponent({

  setup() {
    const dropzoneSingleRef = ref();
    const dropzoneOptions = ref({
      url: 'https://httpbin.org/post',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 0.5,
      maxFiles: 1,
      headers: {}
    });

    provide("bind[dropzoneSingleRef]", el => {
      dropzoneSingleRef.value = el;
    });
    
    
    const db = $_firebase.firestore();
    const uploadimage = ref();
    const uploadtag   = ref();
    const resultbox   = ref();
    const saveFirebase = (bucket,filename) => {
      let item = {
        uri: 'gs://'+bucket+'/'+filename,
       }
       db.collection('image').add(item)
        .then(doc => console.log(doc))
        .catch(err => console.log(err))
      
      uploadimage.value = 'https://storage.googleapis.com/'+bucket+'/'+filename,
      uploadtag.value   = "Primera calidad";
      resultbox.value   = true;
    };
    
    
    onMounted(() => {
      const bucketName      = 'ais-vision-ai.appspot.com';
      const bucketFolder    = 'upload';
      const gsToken         = "ya29.a0AfH6SMA7jRJJAc7IVMhIOfdcp3hFkupO1z97a8AJQFmtxZJ6UjnUO5cDgRuRlMBPVqT0J5jfKyOdmMpCRIYUMruxjSm5Jj0L3XU3YpnAWQ5uLmVinFAvLh2iXeEZzx5aYR11T5icd_qCNI9GELOmP2l4Q7Za";

      const elDropzoneSingleRef = dropzoneSingleRef.value;
      elDropzoneSingleRef.dropzone.on("thumbnail", (file) => { //xhr
        elDropzoneSingleRef.dropzone.options.headers["Authorization"] = "Bearer "+gsToken;
        elDropzoneSingleRef.dropzone.options.headers["Content-Type"] = file.type;
        elDropzoneSingleRef.dropzone.options.headers["Content-Length"] = file.size;
        elDropzoneSingleRef.dropzone.options.url = 'https://storage.googleapis.com/upload/storage/v1/b/'+bucketName+'/o?uploadType=media&name='+bucketFolder+'/'+file.name+'&projection=full'
        elDropzoneSingleRef.dropzone.processQueue()
        elDropzoneSingleRef.dropzone.options.autoProcessQueue = true;
      });
      
      elDropzoneSingleRef.dropzone.on("sending", (file, xhr) => { 
          let _send = xhr.send;
          xhr.send = function() {
            _send.call(xhr, file);
          }
      });
      elDropzoneSingleRef.dropzone.on("success", (file) => {
        console.log(file.upload);
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          newWindow: true,
          close: true,
          gravity: "top",
          position: "center",
          stopOnFocus: true
        }).showToast();
        saveFirebase(bucketName, bucketFolder+'/'+file.name)
      });
      elDropzoneSingleRef.dropzone.on("complete", function(file) {
        elDropzoneSingleRef.dropzone.removeFile(file);
      });

    });
    return {
      dropzoneOptions,
      resultbox,
      uploadimage,
      uploadtag
    }
  },
 methods: {
    getuploadImage() {
      return this.uploadimage;
    },
  }
});
</script>
