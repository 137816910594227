import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";

const views = {
  async get() {
    let tabs = []
    await $_firebase.firestore().collection('view').get()
                    .then(snapshot => { snapshot.forEach(async doc => { let view = doc.data(); view.id  = doc.id; tabs.push(this.setTab(view)) }); });
    return tabs;
  },
  async getSettings(viewId,param=false) {
    let viewSettings = false;
    await $_firebase.firestore().collection('view').doc(viewId).get().then((doc) => { if(doc.exists){ viewSettings = doc.data(); if(param)viewSettings = viewSettings[param] } 
    }).catch((error) => { console.log("Error getting document:", error); return false;});
    return viewSettings;
  },
  setTab(t){
    let name  = t.id; let params = {}; let icon = 'EditIcon'
    for (const [key, p] of Object.entries(t)){ if(key!='id')params[key] = p } 
    if(t.icon)icon = t.icon; 
    if(t.title)name = t.title; 
    return { id: t.id,  name: name, icon: icon, params: $h.orderParams(params) }
  },
  async add(view,msg=false){
    if(view)await $_firebase.firestore().collection("view").doc(view.value).set({ icon: "BoxIcon", title: view.value, }).then(function() { if(msg)$h.Notification(msg) });
  },
  async update(viewID,data,msg=false){
    if(viewID)await $_firebase.firestore().collection("view").doc(viewID).set(data).then(function() { if(msg)$h.Notification(msg) });
  },
  async delete(viewID,msg=false){
    if(viewID){  await $_firebase.firestore().collection("view").doc(viewID).delete(); if(msg)$h.Notification(msg) }
  },
  async updateAttr(doc,Attr,msg=false){ await $_firebase.firestore().collection("view").doc(doc).update(Attr).then(function() { if(msg)$h.Notification(msg) });  },
  async deleteAttr(doc,Attr,msg=false){ await $_firebase.firestore().collection('view').doc(doc).set(Attr, { merge: true }).then(function() { if(msg)$h.Notification(msg) }); },
};

const install = app => {
  app.config.globalProperties.$view = views;
};

export { install as default, views as view };
