<template>
    <div>
      <div class="p-5 pb-0 text-center" v-if="session">
        <div class="text-xl mt-2">Informe de sesión</div>
        <div class="mt-1">
          <div class="box p-5 mt-5">
            <div class="flex"><div class="mr-auto">ID</div><div class="font-medium">{{ session.id }}</div></div>
            <div class="flex mt-1"><div class="mr-auto">Monitor</div><div class="text-gray-600">{{ session.monitor }}</div></div>
            <div class="flex mt-1" v-if="session.start"><div class="mr-auto">Fecha inicio</div><div class="text-gray-600">{{ this.helper.getTimestampDate(session.start.toDate(),'date') }} {{ this.helper.getTimestampDate(session.start.toDate(),'time') }}</div></div>
            <div class="flex mt-1" v-if="session.stop"><div class="mr-auto">Fecha fin</div><div class="text-gray-600">{{ this.helper.getTimestampDate(session.stop.toDate(),'date') }} {{ this.helper.getTimestampDate(session.stop.toDate(),'time') }}</div></div>
            <div class="flex mt-1" v-if="session.duration">
              <div class="mr-auto">Duración</div>
              <span class="text-gray-600 pr-2" v-if="session.duration.days">{{ session.duration.days }}d</span>
              <span class="text-gray-600 pr-2" v-if="session.duration.hours">{{ session.duration.hours }}h</span>
              <span class="text-gray-600 pr-2" v-if="session.duration.minutes">{{ session.duration.minutes }}m</span>
              <span class="text-gray-600" v-if="session.duration.seconds">{{ session.duration.seconds }}s</span>
            </div>
            <div class="flex mt-1"><div class="mr-auto">Usuario</div><div class="text-gray-600">{{ session.uid }}</div></div>
            <div class="flex mt-1"><div class="mr-auto">Estado</div><div class="text-gray-600" v-if="session.stop">Finalizada</div><div v-else class="text-green-600">En curso</div></div>
          </div>
        </div>
        <div class="mt-5" v-if="root">
          <div class="box p-5 pt-4" v-if="session.data && session.data.prediction">
              <div class="flex"><div class="mr-auto font-medium text-base">Predicción</div></div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5">
                <div class="mr-auto pt-2">Rest</div>
                <div class="text-gray-600 pt-2" v-if="session.data.prediction.rest">{{session.data.prediction.rest }}</div>
                <div v-else class="text-gray-600 pt-2">{{ session.prediction_rest }}</div>
              </div>
          </div>
        </div>
        <div class="mt-5" v-if="root">
          <div class="box p-5 pt-4" v-if="session.data &&  session.data.controller">
              <div class="flex"><div class="mr-auto font-medium text-base">Controlador</div></div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5 pb-5">
                <div class="mr-auto">UID</div><div class="text-gray-600">{{session.data.controller.uid }}</div>
              </div>
              <div class="flex"><div class="mr-auto font-medium text-base">Entrada</div></div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5">
                <div class="mr-auto" >Dispositivo</div><div class="text-gray-600">{{session.data.controller.input.device }}</div>
              </div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5 pb-5">
                <div class="mr-auto" >Driver</div><div class="text-gray-600">{{session.data.controller.input.driver }}</div>
              </div>
              <div class="flex"><div class="mr-auto font-medium text-base">Salida</div></div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5">
                <div class="mr-auto" >Dispositivo</div><div class="text-gray-600">{{session.data.controller.output.device }}</div>
              </div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5">
                <div class="mr-auto" >Driver</div><div class="text-gray-600">{{session.data.controller.output.driver }}</div>
              </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="box p-5 pt-4" v-if="session.data">
              <div class="flex"><div class="mr-auto font-medium text-base">Imágenes analizadas</div></div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5" v-for="(score,key) in session.data.tags" v-bind:key="key">
                <div class="mr-auto" v-if="tags && tags[key]">{{ tags[key] }}</div><div class="text-gray-600">{{ score }}</div>
              </div>
              <div class="flex mt-2 pt-2 border-t border-gray-200 dark:border-dark-5">
              <div class="mr-auto font-medium text-base">Total</div>
              <div class="font-medium text-base">{{ session.data.total }}</div>
              </div>
          </div>
        </div>
        <div class="mt-5" v-if="session.data">
          <div class="box p-5 pt-4" v-if="session.data.training">
              <div class="flex">
                <div class="text-xs"><span class="font-medium text-base">Modo entrenamiento registrado</span><br /> Además de la categorización, se realiza el almacenamiento de las imágenes captadas por el monitor</div>
              </div>
              <div class="flex mt-1 pt-1 border-t border-gray-200 dark:border-dark-5" v-for="(t,key) in session.data.training" v-bind:key="key">
                <div class="mr-auto" v-if="t.action">{{ t.action }}</div>
                <div class="text-gray-600" v-if="t.time">
                  {{ this.helper.getTimestampDate(t.time.toDate(),'date') }} {{ this.helper.getTimestampDate(t.time.toDate(),'time') }}
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { session as $session } from "@/model/session";
import { helper as $h } from "@/utils/helper";
import { tag as $tag } from "@/model/tag";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    session_id: {
      type: String,
      default: ''
    }
  },
  created () {
    this.helper = $h
  },  
  setup(props) {

    const session = ref();
    const tags    = ref([])
    const store   = useStore();  
    const root    = ref(false);

    const getSession = async (s) => { session.value = await $session.get({doc: s});  }

    watch( () => props.session_id, async () => { await getSession(props.session_id);  });
+   
    watch( () => store.state.main.User.role, async () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    onMounted( async () => { 
      tags.value  = await $tag.init();
      if(props.session_id){
        await getSession(props.session_id);
      }
      if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;
    });

    return {
      session,
      getSession,
      tags,
      root
    };
  }
});
</script>
