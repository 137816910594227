<template>
  <div class="container grid grid-cols-12 -my-3 min-h-screen relative z-10 w-full">
    <!-- Logo & Tabs -->
    <div class="col-span-3 bg-gray-800 py-10">
        <a href="" class="intro-x flex items-center pl-5 mb-8">
            <img alt="Seeds" class="w-40" src="@/assets/images/logo/logorosepetal.png" />
            <span class="text-white text-lg ml-3"> <span class="font-medium">docs</span> </span>
        </a>
        <a v-for="(tab) in Tabs" :key="tab.id" 
           class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" 
           :class="!tab.root ? isTabSel(CurTab,tab.id) : root ? isTabSel(CurTab,tab.id): 'hidden'" @click="viewTab(tab.id)">{{ tab.title }}</a> 
        <a class="intro-x flex items-center px-5 py-3 rounded-l hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer" @click="linkTo('home')"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a> 
    </div>
    <!-- Proyecto -->
    <div v-if="CurTab=='project'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">Proyecto</h1>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800">Descripción</h2>
        <div class="intro-y leading-relaxed">
            <p class="mb-3">Implementación de un clasificador de visión artificial mediante sistemas de aprendizaje supervisado con redes neuronales convolucionales (deep learning).</p>
        </div>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800 mt-10">
            Browser Support
        </h2>
        <div class="w-full grid grid-cols-12 gap-5">
            <div class="intro-y col-span-6 sm:col-span-3 cursor-pointer" v-for="(browser) in browsers" :key="browser">
                <div class="zoom-in flex flex-col items-center p-5 rounded-md bg-gray-800">
                    <img class="w-10" :alt="browser" :src="require(`../../assets/images/browsers/${browser}.png`)">
                    <div class="font-medium mt-3">{{ $h.capitalizeFirstLetter(browser) }}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Instalación -->
    <div v-if="CurTab=='install'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">Instalación</h1>
        <div class="intro-y leading-relaxed mt-1 mb-8" v-if="gitHub">
          <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
              <h2 class="intro-y text-xl font-medium mb-5 border-b border-gray-800"><component :is="gitHub.icon" class="w-6 h-6" /> GitHub</h2>
              <table class="intro-y w-full">
                  <tr v-if="gitHub.repository_url">
                      <td class="border border-gray-700 px-4 py-2 font-medium">Url</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium">
                        <a class="hover:text-white" target="blank" :href="gitHub.repository_url">{{gitHub.repository_url}}</a>
                      </td>
                  </tr>
                  <tr v-if="gitHub.repository_git">
                      <td class="border border-gray-700 px-4 py-2 font-medium">Git</td><td class="border border-gray-700 px-4 py-2 font-medium">{{gitHub.repository_git}}</td>
                  </tr>
              </table>
          </div>
        </div>
        <div class="intro-y leading-relaxed mt-1 mb-10" v-if="fireBas">
          <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
              <h2 class="intro-y text-xl font-medium mb-5 border-b border-gray-800"><component :is="fireBas.icon" class="w-6 h-6" /> Firebase</h2>
              <table class="intro-y w-full">
                  <tr v-if="fireBas.project_name"><td class="border border-gray-700 px-4 py-2 font-medium">Nombre del proyecto</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_name}}</td></tr>
                  <tr v-if="fireBas.project_id"><td class="border border-gray-700 px-4 py-2 font-medium">ID del proyecto</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_id}}</td></tr>
                  <tr v-if="fireBas.project_num"><td class="border border-gray-700 px-4 py-2 font-medium">Número de proyecto</td><td class="border border-gray-700 px-4 py-2 font-medium">{{fireBas.project_num}}</td></tr>
              </table>
          </div>
        </div>
        <h2 class="intro-y text-xl font-medium pb-5 mb-5 border-b border-gray-800">Activos y dependencias de frontend</h2>
        <div class="intro-y leading-relaxed">
            <p class="mb-3">El siguiente proceso se utilizará para instalar la aplicación mediante el administrador de paquetes. </p>
            <ul class="list-decimal ml-6">
                <li class="mt-4">Instalar Node.js: instale la última versión de Node.js desde https://nodejs.org</li>
                <li class="mt-4">Instalar Yarn desde https://yarnpkg.com</li>
                <li class="mt-4">Extraiga los archivos de proyecto descargados a su directorio o carpeta adecuada.</li>
                <li class="mt-4">Consola de acceso: Abra la consola de node.js.</li>
                <li class="mt-4">En la consola, navegue hasta la ubicación donde se encuentra la carpeta principal. </li>
                <li class="mt-4">Navegue al directorio <span class="text-yellow-500 font-medium">rosepetal-ui</span>.</li>
                <li class="mt-4">Ejecute el comando <span class="text-yellow-500 font-medium">yarn install</span> para instalar dependencias de paquetes.</li>
                <li class="mt-4">Ejecute el comando <span class="text-yellow-500 font-medium">yarn serve</span> para comenzar a compilar o <span class="text-yellow-500 font-medium">yarn build</span> para uso en producción .</li>
            </ul>
        </div>
    </div>
    <!-- Guía de uso -->
    <div v-if="CurTab=='guide'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">Guía rápida de uso</h1>
          <div id="faq-accordion-1" class="accordion">
              <div class="accordion-item">
                  <div id="faq-accordion-content-1" class="accordion-header">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-collapse-1" aria-expanded="true" aria-controls="faq-accordion-collapse-1">
                        Controles de monitor
                      </button>
                  </div>
                  <div id="faq-accordion-collapse-1" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
                      <div class="accordion-body text-white-700 dark:text-white-600 leading-relaxed">
                          <div class="intro-y leading-relaxed mt-6">
                              <div class="intro-y leading-relaxed mt-1 mb-8">
                                <div class="intro-y p-8 rounded-md bg-gray-800 mt-1">
                                    <table class="intro-y w-full">
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-2 font-medium camselector w-60">
                                               <img alt="Selector de monitor" class="w-26" src="@/assets/images/doc/cam_selector.png" />
                                            </td>
                                            <td class="border border-gray-700 px-4 py-2 font-normal">
                                              En la parte superior encontrará un selector de monitor que le dará acceso al resto de monitores que tenga asignados.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-2 font-medium"><PlayCircleIcon class="w-6 h-6 mr-2" /> Play</td>
                                            <td class="border border-gray-700 px-4 py-2 font-normal">
                                              Arranca el proceso de captura del monitor.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-2 font-medium"><StopCircleIcon class="w-6 h-6 mr-2" /> Stop</td>
                                            <td class="border border-gray-700 px-4 py-2 font-medium">
                                              Detiene el proceso de captura del monitor.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="border border-gray-700 px-4 py-2 font-medium"><EyeIcon class="w-6 h-6 mr-2" /> Training</td>
                                            <td class="border border-gray-700 px-4 py-2 font-normal">
                                              Si se encuentra seleccionado se inicia el modo de entrenamiento encargado de almacenar las imágenes capturadas por el monitor para el posterior reentreno del modelo.
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-accordion-collapse-2" aria-expanded="true" aria-controls="faq-accordion-collapse-2">
                          Entreno del modelo con datos definitivos y reentreno del modelo
                      </button>
                  </div>
                  <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-2" data-bs-parent="#faq-accordion-1">
                      <div class="accordion-body text-white-700 dark:text-white-600 leading-relaxed">
                          <div class="w-full gap-5 mt-5">
                              <p>
                                - El modelo se entrena con datos de prueba ya etiquetados.<br>
                                - Éstos datos deben ser supervisados manualmente.<br>
                                - Cuantos más datos facilitemos mejor serán las predicciones.<br>
                                <br>
                                Una vez realizado el entreno definitivo con datos de planta supervisados, el modelo de aprendizaje profundo que hemos implementado permite reevaluar el modelo con nuevos datos etiquetados, de manera que es posible ir mejorando la efectividad conforme se amplíe la muestra.
                              </p>
                          </div>
                          <p class="mt-5">
                            Una vez disponga de imágenes almacenadas a partir del modo entrenamiento del monitor puede proceder con el reentreno de modelo:<br><br>
                          </p>
                          <div aling="center"><h3 class="font-medium">Menú principal > Modelo > Entrenamiento</h3></div><br>
                          <p>
                            1. Seleccione la categoría que desea reentrenar.<br>
                            2. Sobre un listado de resultados podrá seleccionar múltiples imágenes para marcarlas en: <span class="font-medium">Asignar categoría</span><br>
                            <br>
                          </p>
                          <div aling="center"><img src="@/assets/images/doc/trainingdoc1.png" /></div><br />
                          <p>
                            3. Seleccione la categoría que desea asignar a las imágenes seleccionadas.<br>
                          </p><br>
                          <div class="justify-center items-center text-center"><img src="@/assets/images/doc/trainingdoc2.png" /></div><br />
                      </div>
                  </div>
              </div>
          </div>

        
        
    </div> 
    <!-- Javascript -->
    <div v-if="CurTab=='components'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">
          <span @click="viewTab(CurTab)" class="cursor-pointer">Javascript</span>
        </h1>
        <div v-for="(obj,type) in LoadObjects" :key="obj">
          <div v-if="Object.keys(obj).length">
            <div v-if="tactive ? tactive==type ? true : false : true">
              <h2 class="intro-y text-xl font-medium pb-3 mb-4 border-b border-gray-800 w-full text-gray-600">{{ $h.capitalizeFirstLetter(type) }}</h2>
              <div class="w-full grid grid-cols-12 gap-5 mb-5">
                  <div class="intro-y col-span-6 sm:col-span-2 cursor-pointer" v-for="(o,idx) in obj" :key="idx">
                      <div class="zoom-in flex flex-col items-center p-5 pt-2 rounded-md bg-gray-800" @click="viewComponent(o[0].id,type)" v-if="o[0].id">
                          <CodesandboxIcon class="w-8 h-8 mt-2" :class="cactive && isSelected(cactive,o[0].id)" />
                          <div class="font-medium mt-2" :class="isSelected(cactive,o[0].id)">{{ o[0].id }}</div>
                      </div>
                  </div>
              </div>
            </div>
          </div>  
        </div>
        <div v-for="(obj,type) in LoadObjects" :key="obj">
          <div v-for="(o,idx) in obj" :key="idx">
            <div class="intro-y p-8 rounded-md bg-gray-800 mt-5 overflow-hidden" v-if="cactive && cactive.toLowerCase()==o[0].id.toLowerCase()">
               <h2 class="intro-y text-xl font-medium mb-1 border-b border-gray-800 text-yellow-500">{{ o[0].id }}</h2>
               <div class="mb-3"><span class="text-gray-600">{{ type }}</span></div>  
               <div class="mb-2" v-if="type=='views'"><span class="text-green-600 cursor-pointer" @click="linkTo(o[0].link)">Visitar vista</span></div>  
               <div class="mb-5" v-if="o[0].path"><span class="text-gray-600">Path:</span> {{ o[0].path }}</div>
               <div class="mb-5" v-if="o[0].props.length">
                    <table class="intro-y w-full">
                        <tr>
                          <td class="border border-gray-700 px-4 py-2 font-medium w-40">Props</td>
                          <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                              <div v-for="p in o[0].props" :key="p">
                              <div v-for="(pv,key) in p" :key="pv">
                                  {{ key }} <span v-if="pv.default">(Default: {{ pv.default }})</span>
                              </div>
                              </div>
                          </td> 
                        </tr>
                    </table> 
               </div>
               <div class="mb-5" v-if="o[0].methods.length">
                  <table class="intro-y w-full">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">Methods</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].methods" :key="p">
                          <div v-for="(pv,key) in p" :key="pv">
                              {{ key }} <span v-if="pv.default">(Default: {{ pv.default }})</span>
                          </div>
                          </div>
                      </td> 
                      </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].functions.length">
                  <div class="text-gray-600 mt-5 mb-3">Functions</div>
                  <table class="intro-y w-full" v-for="p in o[0].functions" :key="p">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40 cursor-pointer" :class="isSelected(mactive,p)" @click="viewMethod(p)">{{ p }}</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-if="mactive==p"><pre class="overflow-hidden rounded-md"><code class="scss text-xs p-0 rounded-md html pt-4 -mb-10 -mt-10 "> {{ o[0].component[p] }}</code></pre></div>
                      </td> 
                      </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].actions.length">
                  <table class="intro-y w-full">
                    <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">Actions</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].actions" :key="p">
                            <div v-for="(pv,key) in p" :key="pv">
                                - {{ key }} 
                            </div>
                          </div>
                      </td> 
                    </tr>
                  </table> 
               </div>
               <div class="mb-5" v-if="o[0].components.length">
                  <table class="intro-y w-full">
                      <tr>
                      <td class="border border-gray-700 px-4 py-2 font-medium w-40">Components</td>
                      <td class="border border-gray-700 px-4 py-2 font-medium method_code">
                          <div v-for="p in o[0].components" :key="p">
                          <div v-for="(pv,key) in p" :key="pv">
                              <div>
                                <div @click="viewComponent(key,getObjType(key))" class="hover:text-yellow-500 cursor-pointer">{{ key }}</div> 
                                <div v-if="pv.__file" class="text-gray-700">({{ pv.__file }})</div>
                              </div> 
                          </div>
                          </div>
                      </td> 
                      </tr>
                  </table> 
                </div>
            </div>
          </div>
        </div>
    </div>
    <!-- Changelog -->
    <div v-if="CurTab=='changelog'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
        <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
        <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800">Changelog</h1>
        <div class="intro-y p-8 rounded-md bg-gray-800">
          <div><span class="text-yellow-500">Current version</span> </div>
          <div class="mt-3">
            <ul class="list-disc ml-6 mt-3">
              <li>{{ General.version }} </li>
            </ul>
          </div>
        </div>
    </div>  
    <!-- Soporte -->
    <div v-if="CurTab=='support'" class="col-span-9 border-l border-gray-800 bg-gray-900 py-10 px-10">
      <div align="right"><a @click="linkTo('home')" class="cursor-pointer"><ChevronLeftIcon class="w-4 h-4 mr-2" />Volver</a></div>
      <h1 class="intro-y text-2xl font-medium pb-8 mb-10 border-b border-gray-800"> Support</h1>
      <div class="intro-y p-8 rounded-md bg-gray-800"> 
        Para soporte y preguntas. Por favor contáctenos en: <a class="hover:text-white font-medium" :href="'mailto:'+General.support_mail">{{ General.support_mail }}</a> 
      </div>
    </div>  
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeMount, computed, watch} from "vue";
import cash from "cash-dom";

//components
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import { helper as $h } from "@/utils/helper";
import _ from "lodash";
import { view as $v } from "@/model/view";
import { event as $event } from "@/model/events";
import { monitor as $monitor } from "@/model/monitor";
import { tag as $tag } from "@/model/tag";
import { layout as $layout } from "@/model/layout";
import { user as $user } from "@/model/user";
import { _firebase as $_firebase } from "@/model/firebase";
import { model as $model } from "@/model/model";
import { settings as $set } from "@/model/settings";
import { table as $table } from "@/model/table";
import sideMenu from "@/store/side-menu";

import ManageApp    from "@/components/manage-app/Main.vue";
import ManageTags   from "@/components/manage-tags/Main.vue";
import ManageEvents from "@/components/manage-events/Main.vue";
import ManageModels from "@/components/manage-models/Main.vue";
import ManageUsers  from "@/components/manage-users/Main.vue";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import ProfileMenu  from "@/components/profile-menu/Main.vue";
import GlobalCounter from "@/components/global-counter/Main.vue";
import MonitorControls from "@/components/monitor-controls/Main.vue";
import MonitorPreview from "@/components/monitor-preview/Main.vue";
import ManageSession from "@/components/manage-session/Main.vue";

import Login  from "@/views/login/Main.vue";
import Profile  from "@/views/profile/Main.vue";
import Monitor  from "@/views/monitor/Main.vue";
import Settings from "@/views/settings/Main.vue";
import Training from "@/views/training/Main.vue";
import Log from "@/views/log/Main.vue";

export default defineComponent({
  setup() {

    const router      = useRouter();
    const store       = useStore();
    const User        = computed(() => store.state.main.User);
    const root        = ref(false);

    //Navigation
    const Tabs           = ref([ { id: 'project',    title: 'Proyecto',     root: true }, 
                                 { id: 'install',    title: 'Instalación',  root: true },
                                 { id: 'guide',      title: 'Guía de uso',  root: false },
                                 { id: 'components', title: 'Javascript',   root: true },
                                 { id: 'changelog',  title: 'Changelog',    root: true },
                                 { id: 'support',    title: 'Soporte',      root: false }
                               ])
    const CurTab         = ref('guide') //Tabs.value[0].id)
    const isTabSel       = (item, value) => { if(item==value)return 'bg-gray-900' };
    const viewTab        = (t) => { CurTab.value = t; cactive.value = tactive.value = mactive.value = false;};

    //Components & Methods
    const components     = ref([
                            { id: 'helper',           type: 'util',          component: $h },
                            { id: 'cash',             type: 'util',          component: cash },
                            { id: 'store',            type: 'util',          component: useStore },
                            { id: 'lodash',           type: 'util',          component: _ },
                            { id: 'view',             type: 'model',         component: $v },
                            { id: 'event',            type: 'model',         component: $event },
                            { id: '_monitor',         type: 'model',         component: $monitor },
                            { id: 'tag',              type: 'model',         component: $tag },
                            { id: 'layout',           type: 'model',         component: $layout },
                            { id: 'user',             type: 'model',         component: $user },
                            { id: 'firebase',         type: 'model',         component: $_firebase },
                            { id: 'model',            type: 'model',         component: $model },
                            { id: 'setting',          type: 'model',         component: $set },
                            { id: 'table',            type: 'model',         component: $table },
                            { id: 'router',           type: 'nav',           component: router },
                            { id: 'sidemenu',         type: 'nav',           component: sideMenu },
                            { id: 'topBar',           type: 'nav',           component: TopBar },
                            { id: 'mobileMenu',       type: 'nav',           component: MobileMenu },
                            { id: 'managesession',    type: 'components',    component: ManageSession },
                            { id: 'manageapp',        type: 'components',    component: ManageApp },
                            { id: 'managetags',       type: 'components',    component: ManageTags },
                            { id: 'manageevents',     type: 'components',    component: ManageEvents },
                            { id: 'managemodels',     type: 'components',    component: ManageModels },
                            { id: 'manageusers',      type: 'components',    component: ManageUsers },
                            { id: 'profileMenu',      type: 'components',    component: ProfileMenu },
                            { id: 'globalCounter',    type: 'components',    component: GlobalCounter },
                            { id: 'monitorControls',  type: 'components',    component: MonitorControls },
                            { id: 'monitorPreview',   type: 'components',    component: MonitorPreview },
                            
                            { id: 'login',            type: 'view',    component: Login,       link: 'login'},
                            { id: 'profile',          type: 'view',    component: Profile,     link: 'side-menu-profile'},
                            { id: 'monitor',          type: 'view',    component: Monitor,     link: 'side-menu-monitor'},
                            { id: 'settings',         type: 'view',    component: Settings,    link: 'side-menu-settings'},
                            { id: 'training',         type: 'view',    component: Training,    link: 'side-menu-training'},
                            { id: 'log',              type: 'view',    component: Log,         link: 'side-menu-log'},
                            
                           ])
    const LoadObjects    = ref({
                                utils: {},
                                navigation: {},
                                models: {},
                                components: {},
                                views: {},
                               })
    const mactive        = ref()
    const tactive        = ref()
    const cactive        = ref()
    const isSelected     = (item, value) => { if(item && item.toLowerCase()==value.toLowerCase())return 'text-yellow-500' } ;
    const viewComponent  = (c,t) => { cactive.value = c; tactive.value = t;};
    const viewMethod     = (m) => { mactive.value = m;};
    const getObjType     = (o) => { let type = tactive.value; for (const [key,obj] of Object.entries(components.value)) { if(key!=null && o.toLowerCase()==obj.id.toLowerCase())type = obj.type.toLowerCase() } return type};
    

    //Other
    const browsers  = ref(false)
    const gitHub    = ref(false);
    const fireBas   = ref(false);
    const General   = ref(false);

    onBeforeMount( () => {  cash("body").removeClass('main').addClass("DocLayout");   });
    onMounted(async () => {
      LoadObjects.value.utils      = $h.getObjectProperty(components.value, 'util')
      LoadObjects.value.components = $h.getObjectProperty(components.value, 'components')
      LoadObjects.value.models     = $h.getObjectProperty(components.value, 'model')
      LoadObjects.value.views      = $h.getObjectProperty(components.value, 'view')
      LoadObjects.value.navigation = $h.getObjectProperty(components.value, 'nav')
      gitHub.value                 = await $v.getSettings('github');
      fireBas.value                = await $v.getSettings('firebase');
      General.value                = await $v.getSettings('settings');
      browsers.value               = await $h.getBrowsers();

     if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true; 
    });

    watch( () => store.state.main.User.role, () => { if(store.state.main.User.role && store.state.main.User.role.role_id==3)root.value = true;  });

    const linkTo = (page) => { console.log(page); cash("body").removeClass("DocLayout").addClass("main");   if(page=='home')router.replace('/'); router.push({ name: page }); };

    return {
      Tabs,
      CurTab,
      viewTab,
      isTabSel,
      router,
      linkTo,
      LoadObjects,
      tactive,
      mactive,
      cactive,
      isSelected,
      components,
      viewComponent,
      getObjType,
      viewMethod,
      browsers,
      gitHub,
      fireBas,
      General,
      User,
      root
    };
  }
});
</script>
<style>
.DocLayout{ color: #cbd5e0; background-color: rgba(45, 55, 72);  }
.method_code{max-width: 600px}
.accordion-button{font-size: 20px !important}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed),.accordion .accordion-collapse{ color:#ffffff !important}
.camselector{background-color:#eef3f7 !important;}
</style>