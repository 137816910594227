import { _firebase as $_firebase } from "@/model/firebase";
import { tag as $tag } from "@/model/tag";
import { helper as $h } from "@/utils/helper";
import moment from 'moment';

const images = {
    async get(doc=false,dates=false){
        if(doc){
            let mon = $_firebase.firestore().collection('image').doc(doc)
            const snapshot = await mon.get(); let item = snapshot.data(); if(item)item.id = snapshot.id
            return item;
        }else{
            let images = []
            let tags   = await $tag.init();
            let set    = $_firebase.firestore().collection('image')
            if(dates){
                if(dates[0]){ let i = moment(dates[0]+" 00:00:00", 'DD/MM/YYYY H:i:s').valueOf(); set = set.where('date', '>=', i); }
                if(dates[1]){ let e = moment(dates[1]+" 23:59:59", 'DD/MM/YYYY H:i:s').valueOf(); set = set.where('date', '<=', e); }
            }
            set  = set.orderBy("date", "desc")
            await set.get().then(snapshot => { snapshot.forEach(async doc => { 
                let item = doc.data();
                let img_base64_val  = null
                if(item.image_data && item.image_data._delegate._byteString.binaryString)img_base64_val = btoa(item.image_data._delegate._byteString.binaryString);
                //let date = new Date(item.date);
                let tagName = tags[item.tag]
                images.push({ 
                    id:      doc.id, 
                    tag:     item.tag, 
                    tagname: tagName,
                    name:    item.name, 
                    date:    $h.getTimestampDate(item.date),
                    seconds: item.date,
                    image:   "",
                    image_data: img_base64_val, 
                    monitor: item.monitor,
                    prediction: item.prediction
                  });
            }); });
            return images
        }
    },
    async byDateRange(dateRange){
        return await this.get(false,dateRange);
    }
};

const install = app => {
  app.config.globalProperties.$images = images;
};

export { install as default, images as image };
